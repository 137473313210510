export interface MarketerTechStacksByMarketerIDModel {
    data: {
        tech_stack_list: string[]
        added_tech_stacks: MarketerTechStacksByMarketerIDTechStackModel[]
        available_tech_stacks: MarketerTechStacksByMarketerIDAvailableTechStacksModel[]
    },
    meta: any | null
}

export interface MarketerTechStacksByMarketerIDTechStackModel {
    tech_stack_id: number;
    tech_stack_name: string;
    sub_tech_stack_name: string;
    years_experience: number;
    icon: string;
}

export interface MarketerTechStacksByMarketerIDAvailableTechStacksModel {
    tech_stack_name: string;
    sub_tech_stack_list: [MarketerTechStacksByMarketerIDTechStackModel];
}

export interface AddMarketerTechStackToMarketer {
    marketer_id: number;
    tech_stack_id: number[];
    tech_stack_name: string;
    sub_tech_stack_name: string;
    years_experience: number;
}

export const initialMarketerTechStacksByMarketerIDTechStackModelState : MarketerTechStacksByMarketerIDTechStackModel = {
    tech_stack_id: 0,
    tech_stack_name: "",
    sub_tech_stack_name: "",
    years_experience: 0,
    icon: ""
}

export const initialMarketerTechStacksByMarketerIDAvailableTechStacksModelState : MarketerTechStacksByMarketerIDAvailableTechStacksModel = {
    tech_stack_name: "",
    sub_tech_stack_list: [initialMarketerTechStacksByMarketerIDTechStackModelState]
}

export const initialMarketerTechStacksByMarketerIDModelState : MarketerTechStacksByMarketerIDModel = {
    data: {
        tech_stack_list: [""],
        added_tech_stacks: [initialMarketerTechStacksByMarketerIDTechStackModelState],
        available_tech_stacks: [{
            tech_stack_name: "",
            sub_tech_stack_list: [initialMarketerTechStacksByMarketerIDTechStackModelState]
        }]
    },
    meta: {}
}

export const initialAddMarketerTechStackToMarketerState : AddMarketerTechStackToMarketer = {
    marketer_id: 0,
    tech_stack_id:[],
    tech_stack_name: "",
    sub_tech_stack_name: "",
    years_experience: 0
}
