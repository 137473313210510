import {
  PartnerItemModel
} from "./PartnerModel";
import {LocationItemModel} from "./LocationModel";
import {CostToCompanyItemModel} from "./CostToCompanyModel";
import {HourlyRateItemModel} from "./HourlyRateModel";

export interface MarketerTechStackListModel {
  data: MarketerTechStackModel[]
  meta: any
}

export interface MarketerTechStackItemModel {
  data: MarketerTechStackModel
  meta: any
}

export interface MarketerTechStackModel{
  id: number;
  attributes: MarketerTechStackAttributesModel;
}

export interface MarketerTechStackAttributesModel {
  tech_stack_id: number;
  marketer_tech_stack_id: number;
  tech_stack_name: string;
  sub_tech_stack_name: string;
  years_experience: number;
  icon: string;
}

export interface UpdateMarketerTechStackModel {
  marketer_tech_stack_id: number
  years_experience: number;
}

export interface DeleteMarketerTechStackModel {
  marketer_tech_stack_id: number
}

export const initialMarketerTechStackAttributesModelState: MarketerTechStackAttributesModel = {
  tech_stack_id: 0,
  marketer_tech_stack_id: 0,
  tech_stack_name: "",
  sub_tech_stack_name: "",
  years_experience: 0,
  icon: ""
}

export const initialMarketerTechStackModelState: MarketerTechStackModel = {
  id: 0,
  attributes: initialMarketerTechStackAttributesModelState
}

export const initialUpdateMarketerTechStackModelState: UpdateMarketerTechStackModel = {
  marketer_tech_stack_id: 0,
  years_experience: 0
}

export const initialDeleteMarketerTechStackModelState: DeleteMarketerTechStackModel = {
  marketer_tech_stack_id: 0
}

export const initialMarketerTechStackListModelState: MarketerTechStackListModel = {
  data: [initialMarketerTechStackModelState],
  meta: null
}
