import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import  {RootState} from "../../../../setup";
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { getMarketerNotifications, updateUser } from "../../../../app/modules/auth/redux/AuthCRUD";
import { initialMarketerJobMatchNotificationsModelState } from '../../../../app/modules/auth/models/MarketerJobMatchModel';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { Switch } from "@mui/material";
import {useLocation} from "react-router";

const toolbarButtonMarginClass = 'ms-1 ms-lg-6 mt-2',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()
  const dispatch = useDispatch()
  const location = useLocation()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [matches, setMatches] = useState(initialMarketerJobMatchNotificationsModelState);
  const [notifications, setNotificationsState] = useState(false);

  useEffect(() => {
    (async () => {
      if(user.user_type == "marketer") {
        const marketer_id = user.profile_id as number
        const {data: matches} = await getMarketerNotifications(marketer_id)
        setMatches(matches)
        if(user.notifications == true || user.notifications == null) {
          setNotificationsState(true);
        } else {
          setNotificationsState(false);
        }
      }
    })()
  },[location.pathname]);

  const updateNotificationState = async () => {
    let updated_user
    if(notifications == true) {
      setNotificationsState(false)
      updated_user = await updateUser({id: user.id, notifications: false})
    } else {
      setNotificationsState(true)
      updated_user = await updateUser({id: user.id, notifications: true})
    }
    dispatch(auth.actions.setUser( updated_user))
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>

      {user.user_type == "marketer" &&
      <>
        {/* NOTIFICATIONS */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
          <div>

          </div>
          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <label className='form-check-label m-3'>Job Notifications: </label>
            <Switch defaultChecked={user.notifications} value={notifications} onChange={updateNotificationState} />
          </div>
        {/* end::Menu wrapper */}
      </div>

      {/* NOTIFICATIONS */}
      
      <div className={notifications == true ? clsx('d-flex align-items-center', toolbarButtonMarginClass) : 'false-notifications'}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx('btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2 position-relative', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen007.svg'
            className={toolbarButtonIconSizeClass}
          />
          {matches && matches.matches != 0 &&
            <span className="notification-badge fs-6">{matches.matches}</span>
          }
        </div>

        <HeaderNotificationsMenu matches={matches}/>
        {/* end::Menu wrapper */}
      </div>
      
      </>
      }

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/avatars/F-Logo.png')} alt='Fundi Digital' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Topbar}
