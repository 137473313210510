import {MarketerStatusItemModel} from "./MarketerStatusModel";
import {MarketerSkillListModel} from "./MarketerSkillModel";
import {MarketerTechStackListModel} from "./MarketerTechStackModel";
import {LocationItemModel} from "./LocationModel";
import {HourlyRateItemModel} from "./HourlyRateModel";
import {CostToCompanyItemModel} from "./CostToCompanyModel";
import {HoursAvailableItemModel} from "./HoursAvailableModel";
import {IndustryListModel} from "./IndustryModel";
import * as Yup from "yup";
import {MainSkillItemModel, MainSkillListModel} from "./MainSkillModel";

export interface MarketerListModel {
  data: MarketerModel[]
  meta: any
}

export interface MarketerItemModel {
  data: MarketerModel
  meta: any
}

export interface MarketerModel {
  id: number
  attributes: MarketerAttributesModel
}

export interface MarketerAttributesModel {
  id: number ;
  email_address: string;
  first_name: string;
  last_name: string;
  id_type: string;
  id_number: string;
  phone_number: string;
  country_of_residence: string;
  portfolio_link: string;
  linked_in_link: string;
  referer_id: string;
  welcome_email: boolean;
  job_type: string;
  years_experience: number;
  working_environment: string;
  marketer_skills: MarketerSkillListModel;
  marketer_tech_stacks: MarketerTechStackListModel;
  marketer_status: MarketerStatusItemModel;
  cost_to_company: CostToCompanyItemModel;
  hourly_rate: HourlyRateItemModel;
  hours_available: HoursAvailableItemModel;
  location: LocationItemModel;
  industries: IndustryListModel;
  main_skills: MainSkillListModel;
  profile_completeness: number;
  profile_completeness_issues: string;
  work_history: string;
  education: string;
  avatar: string;
  gender: string;
  ethnicity: string;
}

export interface CreateMarketerFormModel {
  first_name:  string;
  last_name:  string;
  phone_number: string;
  email_address: string;
  password: string;
  confirm_password: string;
  terms: boolean;
}

export interface AddMarketerFormModel {
  email_address: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  linked_in_link: string;
}

export interface UpdateMarketerFormModel {
  id: number
  first_name: string
  last_name: string
  id_type: string
  id_number: string
  phone_number: string
  portfolio_link: string
  linked_in_link: string
  job_type: string
  years_experience: number
  working_environment: string
  cost_to_company: number | null
  hourly_rate: number | null
  hours_available: number | null
  location: number
  main_skills: number[]
  industries: number[]
  work_history: string;
  education: string;
  avatar: string;
  gender: string;
  ethnicity: string;
}

export interface UpdateMarketerStatusModel {
  marketer_id: number;
  status_id: number;
  interview_date: string;
}

export const initialMarketerAttributesModelState: MarketerAttributesModel = {
  id: 0,
  email_address: "",
  first_name: "",
  last_name: "",
  id_type: "",
  id_number: "",
  phone_number: "",
  country_of_residence: "",
  referer_id: "",
  portfolio_link: "",
  linked_in_link: "",
  welcome_email: false,
  job_type: "",
  years_experience: 0,
  working_environment: "",
  work_history: "",
  education: "",
  marketer_skills: {
    data: [{
      id: 0,
      attributes: {
        skill_id: 0,
        marketer_skill_id: 0,
        skill_name: "",
        sub_skill_name: "",
        years_experience: 0,
        icon: ""
      }
    }],
    meta: {}
  },
  marketer_tech_stacks: {
    data: [{
      id: 0,
      attributes: {
        marketer_tech_stack_id: 0,
        tech_stack_id: 0,
        tech_stack_name: "",
        sub_tech_stack_name: "",
        years_experience: 0,
        icon: ""
      }
    }],
    meta: {}
  },
  marketer_status: {
    data: {
      id: 0,
      attributes: {
        status_name: "",
        url_param: "",
        badge: ""
      }
    },
    meta: {}
  },
  cost_to_company: {
    data: {
      id: 0,
      attributes: {
        cost_to_company: "",
      }
    },
    meta: {}
  },
  hourly_rate: {
    data: {
      id: 0,
      attributes: {
        rate: "",
      }
    },
    meta: {}
  },
  hours_available: {
    data: {
      id: 0,
      attributes: {
        hours: "",
      }
    },
    meta: {}
  },
  location: {
    data: {
      id: 0,
      attributes: {
        name: "",
      }
    },
    meta: {}
  },
  industries: {
    data: [{
      id: 0,
      attributes: {
        name: ""
      }
    }],
    meta: {}
  },
  main_skills: {
    data: [{
      id: 0,
      attributes: {
        skill_name: ""
      }
    }],
    meta: {}
  },
  profile_completeness: 0,
  profile_completeness_issues: "",
  avatar: "",
  gender: "",
  ethnicity: ""
}

export const initialMarketerModelState : MarketerModel = {
  id: 0,
  attributes: initialMarketerAttributesModelState
}

export const initialMarketerItemModelState : MarketerItemModel = {
  data: initialMarketerModelState,
  meta: {}
}

export const initialMarketerListModelState : MarketerListModel = {
  data: [initialMarketerModelState],
  meta: {}
}

export const initialAddMarketerFormModelState : AddMarketerFormModel = {
  first_name: "",
  last_name: "",
  email_address: "",
  phone_number: "",
  linked_in_link: "",
}

export const initialCreateMarketerFormModelState : CreateMarketerFormModel = {
  first_name: "",
  last_name: "",
  email_address: "",
  phone_number: "",
  password: "",
  confirm_password: "",
  terms: false
}


export const initialUpdateMarketerFormModelState : UpdateMarketerFormModel = {
  id: 0,
  first_name: "",
  last_name: "",
  id_type: "",
  id_number: "",
  phone_number: "",
  portfolio_link: "",
  linked_in_link: "",
  job_type: "",
  years_experience: 0,
  working_environment: "",
  cost_to_company: 0,
  hourly_rate: 0,
  hours_available: 0,
  location: 0,
  main_skills: [0],
  industries: [0],
  work_history: "",
  education: "",
  avatar: "",
  gender: "",
  ethnicity: ""
}

export const initialUpdateMarketerStatusModelState : UpdateMarketerStatusModel = {
  marketer_id: 0,
  status_id: 0,
  interview_date: ""
}
