import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

export function PrivateRoutes() {

  const AccountPage = lazy(() => import('../modules/marketer/MarketerPage'))
  const ReferralsPage = lazy(() => import('../modules/referrals/ReferralsPage'))
  const NewsPage = lazy(() => import('../modules/news/NewsPage'))
  const PartnersPage = lazy(() => import('../modules/partners/PartnersPage'))
  const JobsPage = lazy(() => import('../modules/jobs/JobsPage'))
  const MarketersPage = lazy(() => import('../modules/marketers/MarketersPage'))
  const RegistrationPage = lazy(() => import('../modules/registration/RegistrationPage'))
  const TermsPage = lazy(() => import('../modules/terms/TermsPage'))
  

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path='/terms' component={TermsPage} />
        <Route path='/register' component={RegistrationPage} />
        <Route path='/dash' component={DashboardWrapper} />
        <Route path='/account' component={AccountPage} />
        <Route path='/referrals' component={ReferralsPage} />
        <Route path='/news' component={NewsPage} />
        <Route path='/partners' component={PartnersPage} />
        <Route path='/jobs' component={JobsPage} />
        <Route path='/marketers' component={MarketersPage} />
        <Redirect from='/' to='/account'/>
        <Redirect from='/auth' to='/account'/>
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
