import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
          main: '#2041e0',
        },
        secondary: {
          main: '#f9cc33',
        },
    },
    typography: {
        fontFamily: ['Poppins', 'Open Sans'].join(','),
        fontSize: 13,
        fontWeight: 400
    }
})

export default theme;
