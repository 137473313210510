import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import * as Yup from 'yup'
import "yup-phone";
import {useFormik} from 'formik'
import { initialMarketerItemModelState, initialUpdateMarketerFormModelState, UpdateMarketerFormModel } from "../auth/models/MarketerModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import { getCostToCompanyList, getHourlyRateList, getHoursAvailableList, getIndustryList, getLocationList, getMarketerByID, updateMarketer, upload, getMainSkillList, signupMarketer } from "../auth/redux/AuthCRUD";
import {convertToRaw, convertFromHTML, EditorState, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormControl, MenuItem, Select, TextField, Input, LinearProgress, Box, Slider, CircularProgress, Button } from "@mui/material";
import {initialCostToCompanyListModelState} from "../auth/models/CostToCompanyModel";
import {initialHourlyRateListModelState} from "../auth/models/HourlyRateModel";
import {initialHoursAvailableListModelState} from "../auth/models/HoursAvailableModel";
import {initialLocationListState} from "../auth/models/LocationModel";
import {initialIndustryListState} from "../auth/models/IndustryModel";
import {useHistory, useParams } from 'react-router-dom';
import {ParamsModel} from "../auth/models/ParamsModel";
import {initialMainSkillListModelState} from '../auth/models/MainSkillModel';

const draftToHtml = require('draftjs-to-html');

const marketerSchema = Yup.object().shape({
    avatar: Yup.string().optional(),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    gender: Yup.string().required('Gender is required'),
    ethnicity: Yup.string().required('Ethnicity is required'),
    id_type: Yup.string().required('ID type is required'),
    id_number: Yup.string().required('ID / Passport number is required'),
    phone_number: Yup.string().phone("ZA", true, 'Please enter a customer phone number in the format 071 000 0000').required('Please add a customer phone number').typeError('Please add a customer phone number'),
    linked_in_link: Yup.string().required('Linked In is required'),
    portfolio_link: Yup.string().optional().nullable(),
    job_type: Yup.string().required('Job type is required'),
    years_experience: Yup.string().required('Years experience is required'),
    working_environment: Yup.string().required('Working environment is required'),
    cost_to_company: Yup.number().when("job_type", {
        is: "permanent",
        then: Yup.number().integer()
            .min(1, "Please select a cost to company").required('Cost to company is required'),
    }),
    hourly_rate: Yup.number().when("job_type", {
        is: "freelance",
        then: Yup.number().integer()
            .min(1, "Please select an hourly rate").required('Hourly rates is required'),
    }),
    hours_available: Yup.number().when("job_type", {
        is: "freelance",
        then: Yup.number().integer()
            .min(1, "Please select hours available").required('Hours available is required'),
    }),
    location: Yup.number().integer().min(1, "Please select a location").required('Location is required'),
    main_skills: Yup.number().integer().min(1, "Please select a main skill").required('Main Skill is required'),
    industries: Yup.number().integer().min(1, "Please select an industries").required('Industries is required'),
})

export type Props = {
    steps: any
    step: number
    handleNext: () => void
    handleBack: () => void
}

const SignUpPersonal: React.FC<Props> = ({steps, step, handleNext, handleBack}) => {

    const history = useHistory(); 

    const { marketer_id } = useParams<ParamsModel>();
    console.log('SignUpPersonal marketer_id', marketer_id)

    const [marketer, setMarketer] = useState(initialMarketerItemModelState)
    const [cost_to_company, setCostToCompany] = useState(initialCostToCompanyListModelState)
    const [hourly_rate, setHourlyRate] = useState(initialHourlyRateListModelState)
    const [hours_available, setHoursAvailable] = useState(initialHoursAvailableListModelState)
    const [location, setLocation] = useState(initialLocationListState)
    const [industry, setIndustry] = useState(initialIndustryListState)
    const [main_skill_list, setMainSkillList] = useState(initialMainSkillListModelState);
    const [form_loading, setFormLoading] = useState(false)
    const [refresh_page, setRefreshPage] = useState(false)
    const [is_uploading, setIsUploading] = useState(false)

    {/* Page Refresh */}
    const [loading, setLoading] = useState(true);

    function valuetext(value: any) {
        if (value == 10) {
            return `${value}+ years`;
        } else if (value == 1) {
            return `${value} year`;
        } else {
            return `${value} years`;
        }
    }

    useEffect(() => {
        (async () => {

            setLoading(true)
            await setMarketerValuesForPage();

            const {data: cost_to_company} = await getCostToCompanyList();
            setCostToCompany(cost_to_company);
            const {data: hourly_rate} = await getHourlyRateList();
            setHourlyRate(hourly_rate);
            const {data: hours_available} = await getHoursAvailableList();
            setHoursAvailable(hours_available);
            const {data: location} = await getLocationList();
            setLocation(location);
            const {data: industry} = await getIndustryList();
            setIndustry(industry);
            const {data: main_skill} = await getMainSkillList();
            setMainSkillList(main_skill);
            setLoading(false)

        })()
    }, []);

    const uploadAvatar = async (e: any) => {
        const maxSize = 2 * 1024 * 1024; // 2MB maximum file size
        const file = e.target.files[0];

        if (file.size > maxSize) {
          // Show an error message or perform any other action for exceeding file size
          console.error("File size exceeds the maximum limit");
          formik.setFieldError(
            "avatar",
            "File size exceeds the size limit of 2Mb"
          );
          return;
        }
        setIsUploading(true);
        const { data: response }: any = await upload(file);
        const value = `${response[0].url}`
        await formik.setFieldValue("avatar", value)
        setIsUploading(false)
        setRefreshPage(true)
    }

    useEffect(() => {
        (async () => {
            if (refresh_page) {
                // await setMarketerValuesForPage();
                setRefreshPage(false)
            }
        })();
    }, [refresh_page]);

    const setMarketerValuesForPage = async () => {

        const {data: marketer} = await getMarketerByID(Number(marketer_id))
        setMarketer(marketer)

        formik.setFieldValue("id", marketer_id)
        formik.setFieldValue("avatar", marketer.data.attributes.avatar ? marketer.data.attributes.avatar : "");
        formik.setFieldValue("first_name", marketer.data.attributes.first_name ? marketer.data.attributes.first_name : "");
        formik.setFieldValue("last_name", marketer.data.attributes.last_name ? marketer.data.attributes.last_name : "");
        formik.setFieldValue("gender", marketer.data.attributes.gender ? marketer.data.attributes.gender : "");
        formik.setFieldValue("ethnicity", marketer.data.attributes.ethnicity ? marketer.data.attributes.ethnicity : "");
        formik.setFieldValue("id_type", marketer.data.attributes.id_type ? marketer.data.attributes.id_type : "");
        formik.setFieldValue("id_number", marketer.data.attributes.id_number ? marketer.data.attributes.id_number : "");
        formik.setFieldValue("phone_number", marketer.data.attributes.phone_number ? marketer.data.attributes.phone_number : "");
        formik.setFieldValue("portfolio_link", marketer.data.attributes.portfolio_link ? marketer.data.attributes.portfolio_link : "");
        formik.setFieldValue("linked_in_link", marketer.data.attributes.linked_in_link ? marketer.data.attributes.linked_in_link : "");
        formik.setFieldValue("job_type", marketer.data.attributes.job_type ? marketer.data.attributes.job_type : "");
        formik.setFieldValue("years_experience", marketer.data.attributes.years_experience ? marketer.data.attributes.years_experience : 0);
        formik.setFieldValue("working_environment", marketer.data.attributes.working_environment ? marketer.data.attributes.working_environment : "");

        let main_skills_arr = [];
        if (marketer.data.attributes.main_skills?.data && marketer.data.attributes.main_skills?.data.length != 0) {
            for (let i = 0; i < marketer.data.attributes.main_skills?.data.length; i++) {
                const skill = marketer.data.attributes.main_skills?.data[i];
                main_skills_arr.push(skill.id);
            }
        }

        formik.setFieldValue("main_skills", marketer.data.attributes.main_skills?.data ? main_skills_arr : main_skills_arr);

        formik.setFieldValue("cost_to_company", marketer.data.attributes.cost_to_company?.data?.id ? marketer.data.attributes.cost_to_company?.data.id : 0);
        formik.setFieldValue("hourly_rate", marketer.data.attributes.hourly_rate?.data?.id ? marketer.data.attributes.hourly_rate?.data.id : 0);
        formik.setFieldValue("hours_available", marketer.data.attributes.hours_available?.data?.id ? marketer.data.attributes.hours_available?.data.id : 0);
        formik.setFieldValue("location", marketer.data.attributes.location?.data?.id ? marketer.data.attributes.location?.data.id : 0);

        let industries_arr = [];
        if (marketer.data.attributes.industries?.data && marketer.data.attributes.industries?.data.length != 0) {
            for (let i = 0; i < marketer.data.attributes.industries?.data.length; i++) {
                const industry = marketer.data.attributes.industries?.data[i];
                industries_arr.push(industry.id);
            }
        }

        formik.setFieldValue("industries", marketer.data.attributes.industries?.data ? industries_arr : industries_arr);
        formik.setFieldValue("work_history", marketer.data.attributes.work_history ? marketer.data.attributes.work_history : "");
        formik.setFieldValue("education", marketer.data.attributes.education ? marketer.data.attributes.education : "");
    }

    const initialValues = initialUpdateMarketerFormModelState
    const formik = useFormik<UpdateMarketerFormModel>({
        initialValues,
        validationSchema: marketerSchema,
        onSubmit: (values) => {
            setFormLoading(true)
            setTimeout(async () => {
                if (values.job_type == 'permanent') {
                    values.hourly_rate = null;
                    values.hours_available = null;
                } else if (values.job_type == 'freelance') {
                    values.cost_to_company = null;
                }
                try {
                    const marketer = await updateMarketer(values)
                    setMarketer(marketer)
                    handleNext()
                } catch (e) {
                    console.log(e)
                }
                setFormLoading(false)
            }, 1000)
        },
    })

    console.log("Errors: ", formik.errors);

    if (loading) {
        return (
            <Box sx={{width: '100%'}}>
                <LinearProgress color='primary'/>
            </Box>
        )
    } else {
        return (
          <>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              className="form card-header-stepper"
            >
              {/* Avatar */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Avatar</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <div
                    className="image-input image-input-outline "
                    data-kt-image-input="true"
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        "/media/avatars/blank.png"
                      )})`,
                    }}
                  >
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      style={{
                        backgroundImage: `url(${
                          formik.getFieldMeta("avatar").value
                        })`,
                      }}
                    ></div>
                  </div>
                  {is_uploading ? (
                    <CircularProgress className="avatar-loader" />
                  ) : (
                    <FormControl className="p-12">
                      <Input
                        type="file"
                        onChange={uploadAvatar}
                        error={!!formik.errors.avatar} // Convert to boolean using double negation
                      />

                      <div style={{ paddingTop: "0.5rem", fontSize: "larger" }}>
                        Max filesize: 2Mb
                      </div>
                      {formik.errors.avatar && (
                        <div
                          style={{
                            color: "red",
                            paddingTop: "0.5rem",
                            fontSize: "larger",
                          }}
                        >
                          {formik.errors.avatar}
                        </div>
                      )}
                    </FormControl>
                  )}
                </div>
              </div>

              {/* Gender */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Gender</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <Select
                      {...formik.getFieldProps("gender")}
                      id="gender"
                      error={
                        formik.touched.gender && formik.errors.gender
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={0}></MenuItem>
                      <MenuItem value={`Female`}>Female</MenuItem>
                      <MenuItem value={`Male`}>Male</MenuItem>
                      <MenuItem value={`Transgender`}>Transgender</MenuItem>
                      <MenuItem value={`Non-binary/Non-conforming`}>
                        Non-binary/Non-conforming
                      </MenuItem>
                      <MenuItem value={`Prefer not to respond`}>
                        Prefer not to respond
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* Ethnicity */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Ethnicity</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <Select
                      {...formik.getFieldProps("ethnicity")}
                      id="ethnicity"
                      error={
                        formik.touched.ethnicity && formik.errors.ethnicity
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={0}></MenuItem>
                      <MenuItem value={`African`}>African</MenuItem>
                      <MenuItem value={`White`}>White</MenuItem>
                      <MenuItem value={`Coloured`}>Coloured</MenuItem>
                      <MenuItem value={`Asian`}>Asian</MenuItem>
                      <MenuItem value={`Indian`}>Indian</MenuItem>
                      <MenuItem value={`Prefer not to respond`}>
                        Prefer not to respond
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* ID Type */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">ID Type</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <Select
                      {...formik.getFieldProps("id_type")}
                      id="id_type"
                      error={
                        formik.touched.id_type && formik.errors.id_type
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={0}></MenuItem>
                      <MenuItem value={`id_document`}>
                        Identification Document
                      </MenuItem>
                      <MenuItem value={`passport`}>Passport</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* ID Number */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">ID / Passport Number</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <TextField
                      {...formik.getFieldProps("id_number")}
                      id="id_number"
                      variant="standard"
                      error={
                        formik.touched.id_number && formik.errors.id_number
                          ? true
                          : false
                      }
                    />
                  </FormControl>
                </div>
              </div>

              {/* Portfolio Link */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">LinkedIn</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <TextField
                      {...formik.getFieldProps("linked_in_link")}
                      id="linked_in_link"
                      variant="standard"
                      error={
                        formik.touched.linked_in_link &&
                        formik.errors.linked_in_link
                          ? true
                          : false
                      }
                    />
                  </FormControl>
                </div>
              </div>

              {/* Portfolio Link */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Portfolio Link</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <TextField
                      {...formik.getFieldProps("portfolio_link")}
                      id="portfolio_link"
                      variant="standard"
                      error={
                        formik.touched.portfolio_link &&
                        formik.errors.portfolio_link
                          ? true
                          : false
                      }
                    />
                  </FormControl>
                </div>
              </div>

              {/* Location */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Location</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <Select
                      {...formik.getFieldProps("location")}
                      id="remote"
                      error={
                        formik.touched.location && formik.errors.location
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={0}></MenuItem>
                      {location.data.map((value, index) => (
                        <MenuItem key={index} value={value.id}>
                          {value.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* Industry */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Industry Experience</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <Select
                      {...formik.getFieldProps("industries")}
                      multiple
                      id="industries"
                      error={
                        formik.touched.industries && formik.errors.industries
                          ? true
                          : false
                      }
                    >
                      <MenuItem disabled={true} value={0}>
                        <span className="fw-bold"></span>Select Multiple Options
                      </MenuItem>
                      {industry.data.map((value, index) => (
                        <MenuItem key={index} value={value.id}>
                          {value.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* Type of Marketer */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Type of Marketer</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <Select
                      multiple
                      {...formik.getFieldProps("main_skills")}
                      id="main_skills"
                      error={
                        formik.touched.main_skills && formik.errors.main_skills
                          ? true
                          : false
                      }
                    >
                      <MenuItem disabled={true} value={0}>
                        <span className="fw-bold"></span>Select Multiple Options
                      </MenuItem>
                      {main_skill_list.data.map((value, index) => (
                        <MenuItem key={index} value={value.id}>
                          {value.attributes.skill_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* Work Environment */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Preferred Work Environment</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <Select
                      {...formik.getFieldProps("working_environment")}
                      id="working_environment"
                      error={
                        formik.touched.working_environment &&
                        formik.errors.working_environment
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={0}></MenuItem>
                      <MenuItem value={`agency`}>Agency</MenuItem>
                      <MenuItem value={`client`}>Client-side</MenuItem>
                      <MenuItem value={`either`}>I dont mind</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* Job Type */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">I’m looking for</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <FormControl fullWidth variant="standard">
                    <Select
                      {...formik.getFieldProps("job_type")}
                      id="job_type"
                      error={
                        formik.touched.job_type && formik.errors.job_type
                          ? true
                          : false
                      }
                    >
                      <MenuItem value={0}></MenuItem>
                      <MenuItem value={`permanent`}>
                        Permanent opportunities
                      </MenuItem>
                      <MenuItem value={`freelance`}>
                        Freelancer opportunities
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* Years Experience */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Years Experience</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Slider
                    aria-label="Default"
                    defaultValue={formik.getFieldMeta("years_experience").value}
                    valueLabelDisplay="auto"
                    step={1}
                    getAriaValueText={valuetext}
                    valueLabelFormat={valuetext}
                    marks={false}
                    min={0}
                    max={10}
                    {...formik.getFieldProps("years_experience")}
                  />
                  {formik.touched.years_experience &&
                    formik.errors.years_experience && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.years_experience}
                        </div>
                      </div>
                    )}
                </div>
              </div>

              {/* Cost to Company */}
              {formik.getFieldMeta("job_type").value == "permanent" && (
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span className="required">Desired Cost to Company</span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    <FormControl fullWidth variant="standard">
                      <Select
                        {...formik.getFieldProps("cost_to_company")}
                        id="cost_to_company"
                        error={
                          formik.touched.cost_to_company &&
                          formik.errors.cost_to_company
                            ? true
                            : false
                        }
                      >
                        <MenuItem value={0}></MenuItem>
                        {cost_to_company.data.map((value, index) => (
                          <MenuItem key={index} value={value.id}>
                            {value.attributes.cost_to_company}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              {/* Hourly Rate */}
              {formik.getFieldMeta("job_type").value == "freelance" && (
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span className="required">Hourly Rate</span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    <FormControl fullWidth variant="standard">
                      <Select
                        {...formik.getFieldProps("hourly_rate")}
                        id="hourly_rate"
                        error={
                          formik.touched.hourly_rate &&
                          formik.errors.hourly_rate
                            ? true
                            : false
                        }
                      >
                        <MenuItem value={0}></MenuItem>
                        {hourly_rate.data.map((value, index) => (
                          <MenuItem key={index} value={value.id}>
                            {value.attributes.rate}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              {/* Hourly Available */}
              {formik.getFieldMeta("job_type").value == "freelance" && (
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span className="required">Hours Available</span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    <FormControl fullWidth variant="standard">
                      <Select
                        {...formik.getFieldProps("hours_available")}
                        id="hours_available"
                        error={
                          formik.touched.hours_available &&
                          formik.errors.hours_available
                            ? true
                            : false
                        }
                      >
                        <MenuItem value={0}></MenuItem>
                        {hours_available.data.map((value, index) => (
                          <MenuItem key={index} value={value.id}>
                            {value.attributes.hours}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              {!is_uploading && (
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={step === 0 || step === 1}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={form_loading}
                  >
                    {!form_loading && step === steps.length - 1
                      ? "Finish"
                      : "Next"}
                    {form_loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {" "}
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                </Box>
              )}
            </form>
          </>
        );
    }
}
export {SignUpPersonal}