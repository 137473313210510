export interface CostToCompanyListModel {
  data: CostToCompanyModel[]
  meta: any
}

export interface CostToCompanyItemModel {
  data: CostToCompanyModel
  meta: any
}

export interface CostToCompanyModel{
  id: number;
  attributes: CostToCompanyAttributesModel;
}

export interface CostToCompanyAttributesModel {
  cost_to_company: string;
}

export const initialCostToCompanyAttributesModelState: CostToCompanyAttributesModel = {
  cost_to_company: ""
}

export const initialCostToCompanyModelState: CostToCompanyModel = {
  id: 0,
  attributes: initialCostToCompanyAttributesModelState
}

export const initialCostToCompanyListModelState: CostToCompanyListModel = {
  data: [initialCostToCompanyModelState],
  meta: null
}
