export const PAGE_SIZE = 20

export const MARKETER_STATUS_PENDING = 1;
export const MARKETER_STATUS_SCREEN_SUCCESS = 2;
export const MARKETER_STATUS_SCREEN_DECLINED = 3;
export const MARKETER_STATUS_INTERVIEW = 4;
export const MARKETER_STATUS_DECLINED = 5;
export const MARKETER_STATUS_FINAL_VETTING = 6;
export const MARKETER_STATUS_APPROVED = 7;


export const JOB_MATCH_STATUS_PENDING = 1;
export const JOB_MATCH_STATUS_IN_REVIEW =  2;
export const JOB_MATCH_STATUS_TALENT_ADVISOR_MEETING = 3;
export const JOB_MATCH_STATUS_TALENT_ADVISOR_APPROVED = 4;
export const JOB_MATCH_STATUS_CV_SENT_TO_PARTNER = 5;
export const JOB_MATCH_STATUS_1ST_INTERVIEW = 6;
export const JOB_MATCH_STATUS_2ND_INTERVIEW =7;
export const JOB_MATCH_STATUS_3RD_INTERVIEW = 8;
export const JOB_MATCH_STATUS_SUCCESSFUL = 9;
export const JOB_MATCH_STATUS_DECLINED = 10;
export const JOB_MATCH_STATUS_NOT_INTERESTED = 11;
export const JOB_MATCH_STATUS_NO_LONGER_VALID=  12;

