import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import { Box, Stepper, Step, StepLabel } from '@mui/material';

import { SignUpAccount } from './SignUpAccount';
import { SignUpPersonal } from './SignUpPersonal';
import { SignUpSkills } from './SignUpSkills';
import { SignUpTechStack } from './SignUpTechStack';

import { useParams, Link } from 'react-router-dom';
import { ParamsModel } from "../auth/models/ParamsModel";

const steps = ['Create Account', 'Personal Details', 'Your Skills', 'Your Tech Stack' ];

export default function SignUpStepper() {
 
  const { marketer_id } = useParams<ParamsModel>();
  const [activeStep, setActiveStep] = useState(0);
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  useEffect(() => {
    (async () => {
        if(marketer_id) {
            setActiveStep(1);
        }
    })()
  }, []);

  return (
    <>
    <div className='d-flex flex-center pt-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.png')} className='h-45px' />
        </a>
        {/* end::Logo */}
        </div>
    
        <div className='wrapper d-flex flex-column flex-row-fluid signup-wrapper' id='kt_wrapper'>
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <div className='col-xl-10'>
                    <div className='card mb-5 mb-xl-10 border-0' id='kt_profile_details_view'>
                        <div className='card-body p-9'>
                            <Box sx={{ width: '100%' }}>
                                <div className='card-header-stepper border-0'>
                                    <Stepper className='stepper_label' activeStep={activeStep} alternativeLabel>
                                        {steps.map((label, index) => {
                                        const stepProps: { completed?: boolean } = {};
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel sx={{fontSize: '1rem'}}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </div>

                                {activeStep === steps.length ? (
                                    <React.Fragment>
                                        <div className='form card-header-stepper'>
                                            <Box sx={{textAlign: 'center'}}>
                                                <p>Thanks for siging up, You will recieve a email shortly with your deals</p>
                                                <Link to={`/auth`} className='btn btn-primary'>Signin</Link>
                                            </Box>
                                        </div>
                                    </React.Fragment>
                                    ) : (
                                    <React.Fragment>
                                        {/* components here */}
                                        {activeStep === 0 && <SignUpAccount steps={steps} step={activeStep} handleBack={handleBack} handleNext={handleNext}/>}
                                        {activeStep === 1 && <SignUpPersonal steps={steps} step={activeStep} handleBack={handleBack} handleNext={handleNext}/>}
                                        {activeStep === 2 && <SignUpSkills steps={steps} step={activeStep} handleBack={handleBack} handleNext={handleNext}/>}
                                        {activeStep === 3 && <SignUpTechStack steps={steps} step={activeStep} handleBack={handleBack} handleNext={handleNext}/>}
                                    </React.Fragment>
                                )}
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </>
  );
}
