/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {initialMarketerItemModelState, MarketerItemModel, MarketerModel} from '../auth/models/MarketerModel'
import {addMarketerSkill, getMarketerByID, getMarketerSkillsByMarketerID} from '../auth/redux/AuthCRUD'
import * as Yup from 'yup'
import _ from "lodash";
import {useFormik} from 'formik'
import {KTSVG, toAbsoluteUrl} from "../../../_metronic/helpers";
import {
    AddMarketerSkillToMarketer,
    initialAddMarketerSkillToMarketerState,
    initialMarketerSkillsByMarketerIDSkillModelState
} from "../auth/models/MarketerSkillsByMarketerIDModel";
import {FormControl, MenuItem, Select, LinearProgress, Box, Slider, Button} from "@mui/material";
import { UpdateMarketerSkillModal } from '../../../_metronic/partials/layout/UpdateMarketerSkillModal'
import { ConfirmDeleteMarketerSkillModal } from '../../../_metronic/partials/layout/ConfirmDeleteMarketerSkillModal'
import { useParams , Link} from 'react-router-dom';
import {ParamsModel} from "../auth/models/ParamsModel";

const skillsManagementSchema = Yup.object().shape({
    skill_name: Yup.string().required('Skill and Sub Skill are required'),
    skill_id: Yup.number().integer()
        .min(1, "Please select a sub skill").required('Skill and Sub Skill are required'),
    years_experience: Yup.number().integer().min(1, "Please select years experience").required('Years experience is required'),
})

export type Props = {
    steps: any
    step: number
    handleNext: () => void
    handleBack: () => void
}

const SignUpSkills: React.FC<Props> = ({steps, step, handleNext, handleBack}) => {

    const { marketer_id } = useParams<ParamsModel>();

    const [form_loading, setFormLoading] = useState(false)
    const [marketer, setMarketer] = useState(initialMarketerItemModelState)
    const [skill_list, setSkillList] = useState([''])
    const [available_skill_list, setAvailableSkillList] = useState([initialMarketerSkillsByMarketerIDSkillModelState])
    const [sub_skill_list, setSubSkillList] = useState([initialMarketerSkillsByMarketerIDSkillModelState])
    const [added_skills, setAddedSkills] = useState([initialMarketerSkillsByMarketerIDSkillModelState])
    const [reload, setReload] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteSkill, setDeleteSkill] = useState({})
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [updateSkill, setUpdateSkill] = useState({})

    {/* Page Refresh */}
    const [loading, setLoading] = useState(true);

    const updateSubSkillsList = (e: any) => {
        let selected_skill_name = e.target.value
        formik.setFieldValue('skill_name', selected_skill_name)
        let available_sub_skill_list : any

        if(selected_skill_name){
            const available_skill_list_match_array: any = _.filter(available_skill_list, {'skill_name': selected_skill_name});
            const available_skill_list_match: any = available_skill_list_match_array[0]
            available_sub_skill_list = available_skill_list_match.sub_skill_list
            setSubSkillList(available_sub_skill_list)
        } else {
            available_sub_skill_list = []
            setSubSkillList(available_sub_skill_list)
            formik.setFieldValue('skill_id', 0)
        }
    }

    const getMarketerSkills = async () => {
        setLoading(true)
        const {data: marketer} = await getMarketerByID(Number(marketer_id))
        setMarketer(marketer)
        const {data: marketer_skills} = await getMarketerSkillsByMarketerID(Number(marketer_id));
        const skill_list = marketer_skills.data.skill_list
        const added_skill_list: any = marketer_skills.data.added_skills
        const available_skill_list: any = marketer_skills.data.available_skills
        setSkillList(skill_list)
        setAvailableSkillList(available_skill_list)
        setAddedSkills(added_skill_list)
        setLoading(false)
    }

    useEffect(() => {
        (async () => {
            await getMarketerSkills();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await getMarketerSkills();
            formik.setTouched( {})
            setReload(false)
        })();
    }, [reload]);

    function valueText(value: any) {
        if(value == 10){
            return `${value}+ years`;
        } else if (value == 1){
            return `${value} year`;
        } else {
            return `${value} years`;
        }
    }

    const setDeleteSkillChange = async (value: any) => {
        setShowDeleteModal(true)
        setDeleteSkill(value)
    }

    const setUpdateSkillChange = async (value: any) => {
      setShowUpdateModal(true)
      setUpdateSkill(value)
    }

    const closeDeleteModal = async () => {
      setReload(true)
      setShowDeleteModal(false)
    }

    const closeUpdateModal = async () => {
      setReload(true);
      setShowUpdateModal(false)
    }

    const initialValues = initialAddMarketerSkillToMarketerState
    const formik = useFormik<AddMarketerSkillToMarketer>({
        initialValues,
        validationSchema: skillsManagementSchema,
        onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
            setFormLoading(true)
            setTimeout(async () => {
                const add_marketer_skill : AddMarketerSkillToMarketer = {
                    marketer_id: Number(marketer_id),
                    skill_id: values.skill_id,
                    skill_name: "",
                    sub_skill_name: "",
                    years_experience: values.years_experience
                }
                await addMarketerSkill(add_marketer_skill)
                .then(result => {
                    setSubmitting(false);
                    setFormLoading(false)
                    resetForm({});
                    setReload(true)
                  }, errors => {});
            }, 1000)
        },
    })

    return (
        <>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Add Skill</span>
                            <span className='text-muted fw-bold fs-7'>Requires a minimum of 3 skills</span>
                        </h3>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-7'>
                                <span className='required'>Skill Name</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <FormControl fullWidth variant="standard">
                                    <Select
                                        {...formik.getFieldProps('skill_name')}
                                        onChange={updateSubSkillsList}
                                        id="skill_name"
                                        error={(formik.touched.skill_name && formik.errors.skill_name) ? true : false}
                                    >
                                        <MenuItem value={0}></MenuItem>
                                        {skill_list.map((value, index) => (
                                            <MenuItem key={index} value={value}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-7'>
                                <span className='required'>Sub Skill Name</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <FormControl fullWidth variant="standard">
                                    <Select
                                        multiple
                                        {...formik.getFieldProps('skill_id')}
                                        id="skill_id"
                                        error={(formik.touched.skill_id && formik.errors.skill_id) ? true : false}
                                    >
                                        <MenuItem disabled={true} value={0}><span className="fw-bold">Select One or Multiple Options</span></MenuItem>
                                        {sub_skill_list.map((value, index) => (
                                            (value.skill_id != 0 && <MenuItem key={index} value={value.skill_id}>{value.sub_skill_name}</MenuItem>)
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-7'>
                                <span className='required'>Years Experience</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <Slider
                                    aria-label="Default"
                                    defaultValue={formik.getFieldMeta("years_experience").value}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    getAriaValueText={valueText}
                                    valueLabelFormat={valueText}
                                    marks={false}
                                    min={0}
                                    max={10}
                                    {...formik.getFieldProps('years_experience')}
                                />
                                {formik.touched.years_experience && formik.errors.years_experience && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.years_experience}</div>
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={form_loading}>
                                {!form_loading && 'Save Changes'}
                                {form_loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        {loading ?
            <>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color='primary'/>
                </Box>
            </>
            :
            <>
                <div className='col-xl-12'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-10'>
                        {/* begin::Header */}
                        <div className='card-header'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Skills</span>
                                <span className='text-muted fw-bold fs-7'>You have {added_skills.length} skills</span>
                            </h3>
                        </div>
                        {/* end::Header */}
                        {/* begin::Body */}
                        <div className='card-body py-3'>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className='table align-middle gs-0 gy-5'>
                                    {/* begin::Table head */}
                                    <thead>
                                    <tr>
                                        <th className='p-0 w-50px'></th>
                                        <th className='p-0 min-w-200px'></th>
                                        <th className='p-0 min-w-100px'></th>
                                        <th className='p-0 min-w-40px'></th>
                                    </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>

                                    {added_skills.map((value, index) => {
                                        return <tr key={index}>
                                            <th>
                                                <div className='symbol symbol-50px me-2'>
                                                    <span className='symbol-label'>
                                                        <KTSVG path={`/media/icons/duotune/${value.icon}`} className='svg-icon-1 svg-icon-primary align-self-center'/>
                                                    </span>
                                                </div>
                                            </th>
                                            <td>
                                                <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-7'>
                                                    {value.sub_skill_name}
                                                </a>
                                                <span className='text-muted fw-bold d-block fs-7'>{value.skill_name}</span>
                                            </td>
                                            <td>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-muted me-2 fs-7 fw-bold'>{value.years_experience} years</span>
                                                    </div>
                                                    <div className='progress h-6px w-100'>
                                                        <div
                                                            className='progress-bar bg-primary'
                                                            role='progressbar'
                                                            style={{width: `${value.years_experience * 10}%`}}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-end'>
                                                <a href='#' id="kt_update_years_experience" onClick={async () => setUpdateSkillChange(value)} className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'>
                                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                                                </a>
                                                <a href='#' id="kt_delete_confirm" onClick={async () => setDeleteSkillChange(value)} className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'>
                                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                                </a>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                                {/* end::Table */}
                            </div>
                            {/* end::Table container */}
                        </div>
                    </div>
                </div>
            </>
        }
        <UpdateMarketerSkillModal handleClose={closeUpdateModal} show={showUpdateModal} list_item={updateSkill} />
        <ConfirmDeleteMarketerSkillModal handleClose={closeDeleteModal} show={showDeleteModal} list_item={deleteSkill} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
                color="inherit"
                disabled={step === 1}
                onClick={handleBack}
                sx={{ mr: 1 }}
            >
            Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button className='btn btn-primary' onClick={handleNext} disabled={added_skills.length < 3}>
                {step === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
        </Box>
        </>
    );
}
export {SignUpSkills}
