import {PartnerItemModel} from "./PartnerModel";
import {LocationItemModel} from "./LocationModel";
import {JobItemModel} from "./JobModel";
import {MarketerItemModel} from "./MarketerModel";
import {MarketerJobStatusItemModel} from "./MarketerJobStatusModel";
import { MarketerDeclinedStatusItemModel } from "./MarketerDeclinedStatusModel";


export interface MarketerJobMatchListModel {
  data: MarketerJobMatchModel[]
  meta: any
}

export interface MarketerJobMatchItemModel {
  data: MarketerJobMatchModel
  meta: any
}

export interface MarketerJobMatchNotificationsModel {
  matches: number
}

export interface MarketerJobMatchModel{
  id: number;
  attributes: MarketerJobMatchAttributesModel;
}

export interface MarketerJobMatchAttributesModel {
  job: JobItemModel | null;
  marketer: MarketerItemModel | null;
  marketer_job_status: MarketerJobStatusItemModel | null;
  interview_1_date: string;
  interview_2_date: string;
  interview_3_date: string;
  notes: string;
  score: number;
  errors: [string];
  marketer_declined_status: MarketerDeclinedStatusItemModel;
  closed: boolean;
}

export interface UpdateMarketerJobMatchStatusModel {
  marketer_job_match_status_id: number
  marketer_declined_status_id: number
}

export interface MarketerJobMatchParams {
  id: string;
  page: string;
  MarketerJobMatch_id: string;
}

export interface DeclinedStatusesListModel {
  data: DeclinedStatusesModel[]
  meta: any
}

export interface DeclinedStatusesItemModel {
  data: DeclinedStatusesModel
  meta: any
}

export interface DeclinedStatusesModel {
  id: number;
  attributes: DeclinedStatusesAttributesModel;
}

export interface DeclinedStatusesAttributesModel {
  type: string;
  reason: string;
}

export const initialDeclinedStatusesAttributesState: DeclinedStatusesAttributesModel = {
  type: "",
  reason: ""
}

export const initialDeclinedStatusesState: DeclinedStatusesModel = {
  id: 0,
  attributes: initialDeclinedStatusesAttributesState
}

export const initialDeclinedStatusesListState: DeclinedStatusesListModel = {
  data: [initialDeclinedStatusesState],
  meta: null
}



export const initialMarketerJobMatchAttributesState: MarketerJobMatchAttributesModel = {
  job: {
    data: {
      id: 0,
      attributes: {
        partner: null,
        title: "",
        description: "",
        responsibilities: "",
        qualifications: "",
        type: "",
        location: null,
        remote: "",
        cost_to_company: null,
        hourly_rate: null,
        hours_available: null,
        job_skills: {
          data: [{
            id: 0,
            attributes: {
              skill_id: 0,
              job_skill_id: 0,
              skill_name: "",
              sub_skill_name: "",
              years_experience: 0,
              icon: ""
            }
          }],
          meta: {}
        },
        job_tech_stacks: {
          data: [{
            id: 0,
            attributes: {
              tech_stack_id: 0,
              job_tech_stack_id: 0,
              tech_stack_name: "",
              sub_tech_stack_name: "",
              years_experience: 0
            }
          }],
          meta: {}
        },
        main_skills: {
          data: [{
            id: 0,
            attributes: {
              skill_name: ""
            }
          }],
          meta: {}
        },
        start_date: "",
        published: false,
        marketer_job_matches: {
          data: [{
            id: 0,
            attributes: {
              job: null,
              marketer: null,
              marketer_job_status: null,
              interview_1_date: "",
              interview_2_date: "",
              interview_3_date: "",
              notes: "",
              score: 0,
              errors: [""],
              closed: false,
              marketer_declined_status: {
                data: {
                  id: 0,
                  attributes: {
                    reason: "",
                    type: ""
                  }
                },
                meta: {}
              },
            },
          }],
          meta: {}
        },
        job_status: {
          data: {
            id: 0,
            attributes: {
              status_name: "",
              badge: ""
            }
          },
          meta: {}
        },
        marketer_job_matches_total: 0,
        marketer_job_matches_pending: 0,
        marketer_job_matches_processing: 0,
        marketer_job_matches_not_interested: 0,
        marketer_job_matches_invalid: 0,
        marketer_job_matches_declined: 0,
        marketer_job_matches_active: 0,
        insights_missed_marketers: {},
        insights_missed_rate_ctc_marketers: {}
      }
    },
    meta: {}
  },
  marketer: {
    data: {
      id: 0,
      attributes: {
        id: 0,
        email_address: "",
        first_name: "",
        last_name: "",
        id_type: "",
        id_number: "",
        phone_number: "",
        country_of_residence: "",
        referer_id: "",
        portfolio_link: "",
        linked_in_link: "",
        welcome_email: false,
        job_type: "",
        years_experience: 0,
        working_environment: "",
        work_history: "",
        education: "",
        marketer_skills: {
          data: [{
            id: 0,
            attributes: {
              skill_id: 0,
              marketer_skill_id: 0,
              skill_name: "",
              sub_skill_name: "",
              years_experience: 0,
              icon: ""
            }
          }],
          meta: {}
        },
        marketer_tech_stacks: {
          data: [{
            id: 0,
            attributes: {
              marketer_tech_stack_id: 0,
              tech_stack_id: 0,
              tech_stack_name: "",
              sub_tech_stack_name: "",
              years_experience: 0,
              icon: ""
            }
          }],
          meta: {}
        },
        marketer_status: {
          data: {
            id: 0,
            attributes: {
              status_name: "",
              url_param: "",
              badge: ""
            }
          },
          meta: {}
        },
        cost_to_company: {
          data: {
            id: 0,
            attributes: {
              cost_to_company: "",
            }
          },
          meta: {}
        },
        hourly_rate: {
          data: {
            id: 0,
            attributes: {
              rate: "",
            }
          },
          meta: {}
        },
        hours_available: {
          data: {
            id: 0,
            attributes: {
              hours: "",
            }
          },
          meta: {}
        },
        location: {
          data: {
            id: 0,
            attributes: {
              name: "",
            }
          },
          meta: {}
        },
        industries: {
          data: [{
            id: 0,
            attributes: {
              name: ""
            }
          }],
          meta: {}
        },
        main_skills: {
          data: [{
            id: 0,
            attributes: {
              skill_name: ""
            }
          }],
          meta: {}
        },
        profile_completeness: 0,
        profile_completeness_issues: "",
        avatar: "",
        gender: "",
        ethnicity: ""
      }
    },
    meta: {}
  },
  marketer_job_status: {
    data: {
      id: 0,
      attributes: {
        status_name: "",
        url_param: "",
        badge: ""
      }
    },
    meta: {}
  },
  interview_1_date: "",
  interview_2_date: "",
  interview_3_date: "",
  notes: "",
  score: 0,
  errors: [""],
  closed: false,
  marketer_declined_status: {
    data: {
      id: 0,
      attributes: {
        reason: "",
        type: "",
      }
    },
    meta: {}
  },
}

export const initialMarketerJobMatchState: MarketerJobMatchModel = {
  id: 0,
  attributes: initialMarketerJobMatchAttributesState
}

export const initialMarketerJobMatchItemModelState : MarketerJobMatchItemModel = {
  data: initialMarketerJobMatchState,
  meta: {}
}

export const initialMarketerJobMatchListModelState: MarketerJobMatchListModel = {
  data: [initialMarketerJobMatchState],
  meta: null
}

export const initialMarketerJobMatchNotificationsModelState: MarketerJobMatchNotificationsModel = {
  matches: 0
}

export const initialUpdateMarketerJobMatchStatusModelState: UpdateMarketerJobMatchStatusModel = {
  marketer_job_match_status_id: 0,
  marketer_declined_status_id: 0
}
