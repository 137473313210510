/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import * as Yup from 'yup'
import _ from "lodash";
import {useFormik} from 'formik'
import {KTSVG, toAbsoluteUrl} from "../../../_metronic/helpers";
import {FormControl, MenuItem, Select, LinearProgress, Box, Slider, Button} from "@mui/material";
import {addMarketerTechStack, getMarketerByID, getMarketerTechStacksByMarketerID} from '../auth/redux/AuthCRUD'
import { UpdateMarketerTechStackModal } from '../../../_metronic/partials/layout/UpdateMarketerTechStackModal'
import { ConfirmDeleteMarketerTechStackModal } from '../../../_metronic/partials/layout/ConfirmDeleteMarketerTechStackModal'

import {
    AddMarketerTechStackToMarketer,
    initialAddMarketerTechStackToMarketerState,
    initialMarketerTechStacksByMarketerIDTechStackModelState
} from "../auth/models/MarketerTechStacksByMarketerIDModel";

import {initialMarketerItemModelState} from "../auth/models/MarketerModel";
import { useParams, Link } from 'react-router-dom';
import { ParamsModel } from "../auth/models/ParamsModel";

const techStackManagementSchema = Yup.object().shape({
    tech_stack_name: Yup.string().required('Tech Stack and Sub Tech Stack are required'),
    tech_stack_id: Yup.number().integer().min(1, "Please select a sub skill").required('Tech Stack and Sub Tech Stack are required'),
    years_experience: Yup.number().integer().min(1, "Please select years experience").required('Years experience is required'),
})

export type Props = {
    steps: any
    step: number
    handleNext: () => void
    handleBack: () => void
}

const SignUpTechStack: React.FC<Props> = ({steps, step, handleNext, handleBack}) => {

    const { marketer_id } = useParams<ParamsModel>();

    const [reload, setReload] = useState(false)
    const [marketer, setMarketer] = useState(initialMarketerItemModelState)
    const [form_loading, setFormLoading] = useState(false)
    const [tech_stack_list, setTechStackList] = useState([''])
    const [available_tech_stack_list, setAvailableTechStacksList] = useState([initialMarketerTechStacksByMarketerIDTechStackModelState])
    const [sub_tech_stack_list, setSubTechStackList] = useState([initialMarketerTechStacksByMarketerIDTechStackModelState])
    const [added_tech_stacks, setAddedTechStacks] = useState([initialMarketerTechStacksByMarketerIDTechStackModelState])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteTechStack, setDeleteTechStack] = useState({})
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [updateTechStack, setUpdateTechStack] = useState({})

    {/* Page Refresh */}
    const [loading, setLoading] = useState(true);

    const getMarkterTechStacks = async () => {
        const {data: marketer_tech_stack} = await getMarketerTechStacksByMarketerID(Number(marketer_id));
        const tech_stack_list = marketer_tech_stack.data.tech_stack_list
        const added_tech_stacks_list: any = marketer_tech_stack.data.added_tech_stacks
        const available_tech_stack_list: any = marketer_tech_stack.data.available_tech_stacks
        setTechStackList(tech_stack_list)
        setAvailableTechStacksList(available_tech_stack_list)
        setAddedTechStacks(added_tech_stacks_list)
    }

    const updateSubTechStackList = (e: any) => {
        let selected_tech_stack_name = e.target.value
        formik.setFieldValue('tech_stack_name', selected_tech_stack_name)
        let available_sub_tech_stack_list : any

        if(selected_tech_stack_name){
                const available_tech_stack_list_match_array: any = _.filter(available_tech_stack_list, {'tech_stack_name': selected_tech_stack_name});
                const available_tech_stack_list_match: any = available_tech_stack_list_match_array[0]
                available_sub_tech_stack_list = available_tech_stack_list_match.sub_tech_stack_list
                setSubTechStackList(available_sub_tech_stack_list)
        } else {
            available_sub_tech_stack_list = []
            setSubTechStackList(available_sub_tech_stack_list)
            formik.setFieldValue('tech_stack_id', 0)
        }
    }


    function valuetext(value: any) {
        if(value == 10){
            return `${value}+ years`;
        } else if (value == 1){
            return `${value} year`;
        } else {
            return `${value} years`;
        }
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            const {data: marketer} = await getMarketerByID(Number(marketer_id))
            setMarketer(marketer)
            await getMarkterTechStacks();
            setLoading(false)
        })()
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true)
            const {data: marketer} = await getMarketerByID(Number(marketer_id))
            setMarketer(marketer)
            await getMarkterTechStacks();
            setReload(false)
            setLoading(false)
        })()
    }, [reload]);

    const setDeleteTechStackChange = async (value: any, type :string) => {
        setShowDeleteModal(true)
        setDeleteTechStack(value)
    }

    const setUpdateTechStackChange = async (value: any) => {
      setShowUpdateModal(true)
      setUpdateTechStack(value)
    }

    const closeDeleteModal = async () => {
      setReload(true)
      setShowDeleteModal(false)
    }

    const closeUpdateModal = async () => {
        setReload(true);
        setShowUpdateModal(false)
    }

    const initialValues = initialAddMarketerTechStackToMarketerState
    const formik = useFormik<AddMarketerTechStackToMarketer>({
        initialValues,
        validationSchema: techStackManagementSchema,
        onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
            setFormLoading(true)
            setTimeout(async () => {
                    console.log('tech_stack_id', values.tech_stack_id);
                    const add_marketer_skill : AddMarketerTechStackToMarketer = {
                        marketer_id: Number(marketer_id),
                        tech_stack_id: values.tech_stack_id,
                        tech_stack_name: "",
                        sub_tech_stack_name: "",
                        years_experience: values.years_experience
                    }
                    await addMarketerTechStack(add_marketer_skill)
                    .then(result => {
                        setSubmitting(false);
                        setFormLoading(false)
                        resetForm({});
                        setReload(true)
                      }, errors => {});
                    
            }, 1000)
        },
    })

    return (
        <>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Add Tech Stack</span>
                            <span className='text-muted fw-bold fs-7'>Requires a minimum of 3 tech stacks</span>
                        </h3>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-7'>
                                <span className='required'>Tech Stack Name</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <FormControl fullWidth variant="standard">
                                    <Select
                                        {...formik.getFieldProps('tech_stack_name')}
                                        onChange={updateSubTechStackList}
                                        id="skill_name"
                                        error={(formik.touched.tech_stack_name && formik.errors.tech_stack_name) ? true : false}
                                    >
                                        <MenuItem value={0}></MenuItem>
                                        {tech_stack_list.map((value, index) => (
                                            <MenuItem key={index} value={value}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-7'>
                                <span className='required'>Sub Tech Stack Name</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <FormControl fullWidth variant="standard">
                                    <Select
                                        multiple
                                        {...formik.getFieldProps('tech_stack_id')}
                                        id="tech_stack_id"
                                        error={(formik.touched.tech_stack_id && formik.errors.tech_stack_id) ? true : false}
                                    >
                                        <MenuItem disabled={true} value={0}><span className="fw-bold">Select One or Multiple Options</span></MenuItem>
                                        {sub_tech_stack_list.map((value, index) => (
                                            (value.tech_stack_id != 0 &&
                                                <MenuItem key={index} value={value.tech_stack_id}>{value.sub_tech_stack_name}</MenuItem>
                                            )
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-7'>
                                <span className='required'>Years Experience</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <Slider
                                    aria-label="Default"
                                    defaultValue={formik.getFieldMeta("years_experience").value}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    getAriaValueText={valuetext}
                                    valueLabelFormat={valuetext}
                                    marks={false}
                                    min={0}
                                    max={10}
                                    {...formik.getFieldProps('years_experience')}
                                />
                                {formik.touched.years_experience && formik.errors.years_experience && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.years_experience}</div>
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={form_loading}>
                                {!form_loading && 'Save Changes'}
                                {form_loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        {loading ?
        <>
            <Box sx={{ width: '100%' }}>
                <LinearProgress color='primary'/>
            </Box>
            </>
        :
            <div className='col-xl-12'>
                <div className='card card-xxl-stretch mb-5 mb-xl-10'>
                    {/* begin::Header */}
                    <div className='card-header'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Tech Stacks</span>
                            <span className='text-muted fw-bold fs-7'>You have {added_tech_stacks.length} tech stacks</span>
                        </h3>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table align-middle gs-0 gy-5'>
                                {/* begin::Table head */}
                                <thead>
                                <tr>
                                    <th className='p-0 w-50px'></th>
                                    <th className='p-0 min-w-200px'></th>
                                    <th className='p-0 min-w-100px'></th>
                                    <th className='p-0 min-w-40px'></th>
                                </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>

                                {added_tech_stacks.map((value, index) => {
                                    return <tr key={index}>
                                        <th>
                                            <div className='symbol symbol-50px me-2'>
                                            <span className='symbol-label'>
                                            <KTSVG
                                                path={`/media/icons/duotune/${value.icon}`}
                                                className='svg-icon-1 svg-icon-primary align-self-center'
                                            />
                                            </span>
                                            </div>
                                        </th>
                                        <td>
                                            <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-7'>
                                                {value.sub_tech_stack_name}
                                            </a>
                                            <span className='text-muted fw-bold d-block fs-7'>{value.tech_stack_name}</span>
                                        </td>
                                        <td>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    <span
                                                        className='text-muted me-2 fs-7 fw-bold'>{value.years_experience} years</span>
                                                </div>
                                                <div className='progress h-6px w-100'>
                                                    <div
                                                        className='progress-bar bg-primary'
                                                        role='progressbar'
                                                        style={{width: `${value.years_experience * 10}%`}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-end'>
                                            <a href='#' id="kt_update_years_experience" onClick={async () => setUpdateTechStackChange(value)} className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'>
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                                            </a>
                                            <a href='#' id="kt_delete_confirm" onClick={async () => setDeleteTechStackChange(value, 'tech_stack')} className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'>
                                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                            </a>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                </div>
            </div>
        }
        <UpdateMarketerTechStackModal handleClose={closeUpdateModal} show={showUpdateModal} list_item={updateTechStack} />
        <ConfirmDeleteMarketerTechStackModal handleClose={closeDeleteModal} show={showDeleteModal} list_item={deleteTechStack} />                                    
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
                color="inherit"
                disabled={step === 0 ||  step === 1 }
                onClick={handleBack}
                sx={{ mr: 1 }}
            >
            Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button className='btn btn-primary' onClick={handleNext} disabled={added_tech_stacks.length  < 3}>
                {step === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
        </Box>
        </>
    );
}
export {SignUpTechStack};
