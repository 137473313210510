/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {SetPassword} from './components/SetPassword'
import {ResetPassword} from './components/ResetPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        width: "100%",
        backgroundSize: "cover",
        backgroundImage: `url(${toAbsoluteUrl('/media/logos/clouds.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.png')} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto' style={{ position: "relative"}}>
          <div className="bg-login-image" style={{position: "absolute", width: "100%", height: "100%", top: 0, bottom: 0, right: "-100%", backgroundSize: "cover", backgroundPosition: "bottom", backgroundImage: `url(${toAbsoluteUrl('/media/logos/Full-Peeps_01.png')})`}}></div>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/registration' component={Registration} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Route path='/auth/set-password' component={SetPassword} />
            <Route path='/auth/reset-password/:code' component={ResetPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
          <div className="bg-login-image" style={{position: "absolute", width: "100%", height: "100%", top: 0, bottom: 0, left: "-100%", backgroundSize: "cover", backgroundPosition: "bottom", backgroundImage: `url(${toAbsoluteUrl('/media/logos/Full-Peeps_02.png')})`}}></div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='https://www.fundi.digital/why-fundi' target={'_blank'} className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='https://www.fundi.digital/community' target={'_blank'}  className='text-muted text-hover-primary px-2'>
            Hire Marketers
          </a>

          <a href='https://www.fundi.digital/' target={'_blank'}  className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}
