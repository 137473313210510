/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../helpers'
import * as Yup from 'yup'
import _ from "lodash";
import {useFormik} from 'formik'

import {DeleteMarketerTechStackModel, initialDeleteMarketerTechStackModelState} from "../../../app/modules/auth/models/MarketerTechStackModel";
import { deleteMarketerTechStack } from '../../../app/modules/auth/redux/AuthCRUD'

export type Props = {
  show: boolean
  list_item: any
  handleClose: () => void
}

const deleteTechStackSchema = Yup.object().shape({
  marketer_skill_id: Yup.number().integer(),
})

const ConfirmDeleteMarketerTechStackModal: React.FC<Props> = ({show, handleClose, list_item }) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      formik.setFieldValue('marketer_tech_stack_id', list_item.marketer_tech_stack_id)
    })()
}, [list_item]);

const initialValues = initialDeleteMarketerTechStackModelState
const formik = useFormik<DeleteMarketerTechStackModel>({
   initialValues,
   validationSchema: deleteTechStackSchema,
   onSubmit: (values, {setSubmitting, resetForm}) => {
       setLoading(true)
       setTimeout(async () => {
           const delete_marketer_skill : DeleteMarketerTechStackModel = {
            marketer_tech_stack_id: values.marketer_tech_stack_id,
           }
           await deleteMarketerTechStack(delete_marketer_skill)
           .then(result => {
               setSubmitting(false);
               setLoading(false)
               resetForm({});
               handleClose();
             }, errors => {});
       }, 1000)
   },
 })
  
  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_delete_confirm'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={show}
      animation={false}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        <div id='kt_delete_confirm_form'>
          {/*begin::Header*/}
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <h5 className='fw-bold m-0'>Confirm Delete</h5>
            <div className='d-flex ms-2'>
              {/*begin::Close*/}
              <div
                className='btn btn-icon btn-sm btn-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
              {/*end::Close*/}
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className='d-block'>
            <div className='d-flex flex-column w-100 me-2 py-5 ps-8 pe-5'>
                <span className='text-dark mb-1 fs-6'>
                    Please confirm you would like to remove <b>{list_item.sub_tech_stack_name}</b>
                </span>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate className='form d-flex flex-column w-100 me-2 py-5 ps-8 pe-5'>
              <div className='card'>
                <div className='card-footer d-flex justify-content-center py-6 px-9'>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                        {!loading && 'Delete'}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
              </div>
            </form>
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Form*/}
      </div>
    </Modal>
  )
}

export {ConfirmDeleteMarketerTechStackModal}
