import {
  PartnerItemModel
} from "./PartnerModel";
import {LocationItemModel} from "./LocationModel";
import {CostToCompanyItemModel} from "./CostToCompanyModel";
import {HourlyRateItemModel} from "./HourlyRateModel";

export interface MainSkillListModel {
  data: MainSkillModel[]
  meta: any
}

export interface MainSkillItemModel {
  data: MainSkillModel
  meta: any
}

export interface MainSkillModel{
  id: number;
  attributes: MainSkillAttributesModel;
}

export interface MainSkillAttributesModel {
  skill_name: string;
}

export interface UpdateMainSkillModel {
  job_skill_id: number
  years_experience: number;
}

export interface DeleteMainSkillModel {
  job_skill_id: number
}

export const initialMainSkillAttributesModelState: MainSkillAttributesModel = {
  skill_name: ""
}

export const initialMainSkillModelState: MainSkillModel = {
  id: 0,
  attributes: initialMainSkillAttributesModelState
}

export const initialUpdateMainSkillModelState: UpdateMainSkillModel = {
  job_skill_id: 0,
  years_experience: 0
}

export const initialDeleteMainSkillModelState: DeleteMainSkillModel = {
  job_skill_id: 0
}

export const initialMainSkillListModelState: MainSkillListModel = {
  data: [initialMainSkillModelState],
  meta: null
}
