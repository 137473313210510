import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UpdateUserModel, UserModel} from '../models/UserModel'
import {
    AddMarketerFormModel,
    CreateMarketerFormModel,
    MarketerItemModel,
    MarketerListModel,
    UpdateMarketerFormModel,
} from '../models/MarketerModel'
import {
    PartnerItemModel,
    PartnerListModel,
    PartnerAttributesModel,
    AddOrUpdatePartnerModel
} from "../models/PartnerModel";
import {PartnerListListModel} from "../models/PartnerListModel";
import {JobSummaryListModel} from "../models/JobSummaryModel";
import {CostToCompanyListModel} from "../models/CostToCompanyModel";
import {HourlyRateListModel} from "../models/HourlyRateModel";
import {LocationItemModel, LocationListModel} from "../models/LocationModel";
import {
    AddOrUpdateJobModel,
    JobItemModel,
    JobListModel,
    UpdateJobStatusModel
} from "../models/JobModel";
import {AddJobSkillToJob, JobSkillsByJobIDModel } from "../models/JobSkillsByJobIDModel"
import {AddJobTechStackToJob, JobTechStacksByJobIDModel } from "../models/JobTechStacksByJobIDModel"
import {DeleteJobSkillModel, JobSkillItemModel, UpdateJobSkillModel} from "../models/JobSkillModel";
import {DeleteJobTechStackModel, JobTechStackItemModel, UpdateJobTechStackModel} from "../models/JobTechStackModel";
import {AddMarketerSkillToMarketer, MarketerSkillsByMarketerIDModel} from "../models/MarketerSkillsByMarketerIDModel";
import {AddMarketerTechStackToMarketer, MarketerTechStacksByMarketerIDModel} from "../models/MarketerTechStacksByMarketerIDModel";
import {HoursAvailableListModel} from "../models/HoursAvailableModel";
import {IndustryListModel} from "../models/IndustryModel";
import {MarketerStatusListModel} from "../models/MarketerStatusModel";
import {DeleteMarketerSkillModel, MarketerSkillItemModel, UpdateMarketerSkillModel} from "../models/MarketerSkillModel";
import {DeleteMarketerTechStackModel, MarketerTechStackItemModel, UpdateMarketerTechStackModel} from "../models/MarketerTechStackModel";
import {
    DeclinedStatusesListModel,
    MarketerJobMatchItemModel,
    MarketerJobMatchListModel, MarketerJobMatchNotificationsModel, UpdateMarketerJobMatchStatusModel
} from "../models/MarketerJobMatchModel";

import { SkillsModel } from '../models/SkillListModel';
import {MainSkillListModel} from "../models/MainSkillModel";
import {
    JOB_MATCH_STATUS_1ST_INTERVIEW,
    JOB_MATCH_STATUS_2ND_INTERVIEW,
    JOB_MATCH_STATUS_3RD_INTERVIEW,
    JOB_MATCH_STATUS_CV_SENT_TO_PARTNER, JOB_MATCH_STATUS_DECLINED,
    JOB_MATCH_STATUS_IN_REVIEW, JOB_MATCH_STATUS_NO_LONGER_VALID,
    JOB_MATCH_STATUS_NOT_INTERESTED,
    JOB_MATCH_STATUS_PENDING,
    JOB_MATCH_STATUS_SUCCESSFUL,
    JOB_MATCH_STATUS_TALENT_ADVISOR_APPROVED,
    JOB_MATCH_STATUS_TALENT_ADVISOR_MEETING,
    PAGE_SIZE
} from "../../../../config";
import {MarketerJobStatusListModel} from "../models/MarketerJobStatusModel";
import { AddMarketerReferralFormModel, CreateMarketerReferralModel, MarketerReferralListModel, ValidateMarketerReferralEmailModel } from '../models/MarketerReferralModel';
import { JobStatusListModel } from '../models/JobStatusModel';

const API_URL = process.env.REACT_APP_API_URL

export const USERS = `${API_URL}/users/`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/me`
export const LOGIN_URL = `${API_URL}/auth/local/`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const SET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const SET_PASSWORD_DIRECT_URL = `${API_URL}/password/reset`
export const MARKETER = `${API_URL}/marketers/`
export const PARTNER = `${API_URL}/partners/`
export const JOB = `${API_URL}/jobs/`
export const LOCATION = `${API_URL}/locations/`
export const COSTTOCOMPANY = `${API_URL}/cost-to-companies/`
export const HOURLYRATE = `${API_URL}/hourly-rates/`
export const HOURS_AVAILABLE = `${API_URL}/hours-availables/`
export const INDUSTRY = `${API_URL}/industries/`
export const MARKETER_STATUS = `${API_URL}/marketer-statuses/`
export const MARKETER_SKILL = `${API_URL}/marketer-skills/`
export const MARKETER_TECH_STACK = `${API_URL}/marketer-tech-stacks/`
export const MARKETER_JOB_MATCH = `${API_URL}/marketer-job-matches/`
export const MARKETER_JOB_STATUS = `${API_URL}/marketer-job-statuses/`
export const UPLOAD = `${API_URL}/upload/`
export const JOB_SKILL = `${API_URL}/job-skills/`
export const JOB_STATUS = `${API_URL}/job-statuses/`
export const JOB_TECH_STACK = `${API_URL}/job-tech-stacks/`
export const SKILL = `${API_URL}/skills/`
export const MAIN_SKILL = `${API_URL}/main-skills/`
export const REFERRALS = `${API_URL}/referrals/`

const _ = require('lodash');

// Server should return AuthModel
export async function login(email: string, password: string) {

    let data = {
        identifier: email,
        password: password
    }

    let config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    let resp = await axios.post(LOGIN_URL, data, config)
    console.log(resp)
    return resp
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
    return axios.post<AuthModel>(REGISTER_URL, {
        email,
        firstname,
        lastname,
        password,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(email: any) {
    let data = {email: email}
    let config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    return axios.post(REQUEST_PASSWORD_URL, data, config).then(response => {
        return response
    })

}

// Server should return object => { result: boolean } (Is Email in DB)
export function setPassword(code: string, password: string, confirm_password: string) {


    let data = {
        code: code,
        password: password,
        passwordConfirmation: confirm_password
    }

    let config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    return axios.post(SET_PASSWORD_URL, data, config).then(response => {
        return response
    })
}

export async function setPasswordDirect(password: string, new_password: string, confirm_new_password: string) {

    let data = {
        newPassword: new_password,
        currentPassword: password,
        confirmNewPassword: confirm_new_password
    }

    return await axios.post(SET_PASSWORD_DIRECT_URL, data).then(response => {
        return response
    })
}


export async function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    // Check common redux folder => setupAxios
    return await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export async function updateUser(user: UpdateUserModel) {
    return axios.put<UserModel>(`${USERS}${user.id}/`, {
        notifications: user.notifications
    }).then(response => {
        return response.data
    })
}

{/* Marketers */}

export async function addMarketer(marketer: AddMarketerFormModel) {
    return axios.post<MarketerItemModel>(`${MARKETER}`, {
        data: marketer
    }).then(response => {
        return response.data
    })
}

export async function signupMarketer(marketer: CreateMarketerFormModel) {
    return axios.post<MarketerItemModel>(`${MARKETER}signup`, {
        data: marketer
    }).then(response => {
        return response.data
    })
}

export async function updateMarketer(marketer: UpdateMarketerFormModel) {
    return axios.put<MarketerItemModel>(`${MARKETER}${marketer.id}/?populate=*`, {
        data: marketer
    }).then(response => {
        return response.data
    })
}

export async function getMarketerList(page: number, search_query: any) {
    let qs = `?populate=*&sort=createdAt:desc`
    if (page) {
        qs = qs + `&pagination[page]=${page}&pagination[pageSize]=${PAGE_SIZE}`
    }
    if (search_query.query) {
        qs = qs + `&filters[$or][0][first_name][$contains]=${search_query.query}&filters[$or][1][last_name][$contains]=${search_query.query}&filters[$or][2][marketer_skills][sub_skill_name][$contains]=${search_query.query}&filters[$or][3][marketer_tech_stacks][sub_tech_stack_name][$contains]=${search_query.query}`
    }
    if (search_query.status != 0) {
            qs = qs + `&filters[marketer_status][url_param][$eq]=${search_query.status}`
    }

    return axios.get<MarketerListModel>(`${MARKETER}${qs}`)
}

export async function getMarketerByID(id: number) {
    return await axios.get<MarketerItemModel>(`${MARKETER}${id}?populate=*`)
}

export async function getMarketerNotifications(id: number) {
    return await axios.get<MarketerJobMatchNotificationsModel>(`${MARKETER_JOB_MATCH}count/?populate=marketer_job_status&filters[marketer][id][$eq][0]=${id}&filters[marketer_job_status][id][$eq][1]=1`)
}

export async function getMarketersByJobID(id: number) {
    return axios.get<MarketerListModel>(`${MARKETER}?filters[jobs][id]=${id}`)
}

export async function getMarketerSkillsByMarketerID(id: number) {
    return axios.get<MarketerSkillsByMarketerIDModel>(`${MARKETER}${id}/skills`)
}

export async function addMarketerSkill(add_skill: AddMarketerSkillToMarketer) {
    _.forEach(add_skill.skill_id, async function(skill_id :number) {
        await axios.post<MarketerSkillsByMarketerIDModel>(`${MARKETER}${add_skill.marketer_id}/skills`, {
            marketer_id: add_skill.marketer_id,
            skill_id: skill_id,
            skill_name: add_skill.skill_name,
            sub_skill_name: add_skill.sub_skill_name,
            years_experience: add_skill.years_experience
        })
    });   
    return 
}

export async function updateMarketerSkill(update_skill: UpdateMarketerSkillModel) {
    return axios.put<MarketerSkillItemModel>(`${MARKETER_SKILL}${update_skill.marketer_skill_id}`, {
        data: update_skill
    }).then(response => {
        return response.data
    })
}

export async function deleteMarketerSkill(delete_skill: DeleteMarketerSkillModel) {
    return axios.delete<MarketerSkillItemModel>(`${MARKETER_SKILL}${delete_skill.marketer_skill_id}`)
}

export async function getMarketerTechStacksByMarketerID(id: number) {
    return axios.get<MarketerTechStacksByMarketerIDModel>(`${MARKETER}${id}/tech_stacks`)
}

export async function addMarketerTechStack(add_tech_stack: AddMarketerTechStackToMarketer) {
    _.forEach(add_tech_stack.tech_stack_id, async function(tech_stack_id :number) {
        await axios.post<MarketerTechStacksByMarketerIDModel>(`${MARKETER}${add_tech_stack.marketer_id}/tech_stacks`, { 
            marketer_id: add_tech_stack.marketer_id,
            sub_tech_stack_name: add_tech_stack.sub_tech_stack_name,
            tech_stack_id: tech_stack_id,
            tech_stack_name: add_tech_stack.tech_stack_name,
            years_experience: add_tech_stack.years_experience,
         })
      });      
      return
}

export async function updateMarketerTechStack(update_tech_stack: UpdateMarketerTechStackModel) {
    return axios.put<MarketerTechStackItemModel>(`${MARKETER_TECH_STACK}${update_tech_stack.marketer_tech_stack_id}`, {
        data: update_tech_stack
    }).then(response => {
        return response.data
    })
}

export async function deleteMarketerTechStack(delete_tech_stack: DeleteMarketerTechStackModel) {
    return axios.delete<MarketerTechStackItemModel>(`${MARKETER_TECH_STACK}${delete_tech_stack.marketer_tech_stack_id}`)
}

export async function marketerDeclinedStatusList() {
    return await axios.get<DeclinedStatusesListModel>(`${API_URL}/marketer-declined-statuses/`)
}

export async function updateMarketerStatus(marketer_id: number, marketer_status : string) {
    return axios.post<MarketerItemModel>(`${MARKETER}${marketer_id}/status`, {
        status_id: marketer_status,
    }).then(response => {
        return response.data
    })
}

{/* Partners */}

export async function getPartnerList() {
    return axios.get<PartnerListListModel>(`${PARTNER}list`)
}

export async function getPartners(page: number, query: string) {
    let qs = `?pagination[page]=1&pagination[pageSize]=${PAGE_SIZE}`
    if (page) {
        qs = `?pagination[page]=${page}&pagination[pageSize]=${PAGE_SIZE}`
    }
    if (query) {
        qs = qs + `&filters[$or][0][trading_name][$contains]=${query}&filters[$or][1][location][$contains]=${query}`
    }
    return axios.get<PartnerListModel>(`${PARTNER}${qs}`)
}

export async function getPartnerByID(id: number) {
    return await axios.get<PartnerItemModel>(`${PARTNER}${id}`)
}

export async function addPartner(partner: Partial<AddOrUpdatePartnerModel>) {
    return axios.post<PartnerListModel>(`${PARTNER}`, {
        data: partner
    }).then(response => {
        return response.data
    })
}

export async function updatePartner(partner_id: number, partner: Partial<AddOrUpdatePartnerModel>) {
    return axios.put<PartnerListModel>(`${PARTNER}${partner_id}`, {
        data: partner
    }).then(response => {
        return response.data
    })
}

{/* Jobs */}

export async function getJobs(page: number, query: string, summary: boolean) {
    let qs = `?populate=*&sort=createdAt:desc`
    if (page) {
        qs = qs + `&pagination[page]=${page}&pagination[pageSize]=${PAGE_SIZE}`
    }
    if (query) {
        // filter title and location name that contains the query
        qs = qs + `&filters[$or][0][title][$contains]=${query}&filters[$or][1][location][name][$contains]=${query}`
    }
    if (summary) {
        qs = qs + `&summary=true`
    }
    return axios.get<JobListModel>(`${JOB}${qs}`)
}

export async function getAllJobs(page: number, query: any) {

    let counter = 0;
    // filter only jobs that has job_status eq 2 .ie Approved
    let qs = `?populate=*&sort=createdAt:desc&filters[job_status][id][$eq][${counter}]=2`
    if (page) {
        qs = qs + `&pagination[page]=${page}&pagination[pageSize]=${PAGE_SIZE}`
    }
    if (query) {
        qs = qs + ``
    }
    if(query.main_skill != 0) {
        qs = qs + `&filters[main_skill][id][$eq][${counter}]=${query.main_skill}`
        counter++
    }
    if(query.location != 0) {
        qs = qs + `&filters[location][id][$eq][${counter}]=${query.location}`
        counter++
    }
    if(query.remote != 0) {
        qs = qs + `&filters[remote][$eq][${counter}]=${query.remote}`
        counter++
    }
    if(query.job_type != 0) {
        qs = qs + `&filters[type][$eq][${counter}]=${query.job_type}`
        counter++
    }
    if(query.hourly_rate != 0) {
        qs = qs + `&filters[hourly_rate][id][$eq][${counter}]=${query.hourly_rate}`
        counter++
    }
    if(query.hours_available != 0) {
        qs = qs + `&filters[hours_available][id][$eq][${counter}]=${query.hours_available}`
        counter++
    }
    if(query.cost_to_company != 0) {
        qs = qs + `&filters[cost_to_company][id][$eq][${counter}]=${query.cost_to_company}`
        counter++
    }


    return axios.get<JobListModel>(`${JOB}${qs}`)
}

export async function getJobByID(job_id: number) {
    return axios.get<JobItemModel>(`${JOB}${job_id}?populate=*`)
}

export async function getJobsByPartnerID(partner_id: number, page: number, query: string) {
    let qs = `&pagination[page]=1&pagination[pageSize]=${PAGE_SIZE}`
    if (page) {
        qs = `&pagination[page]=${page}&pagination[pageSize]=${PAGE_SIZE}`
    }
    if (query) {
        qs = qs + `&search=${query}`
    }
    return axios.get<JobListModel>(`${JOB}?populate=*&sort=createdAt:desc&filters[partner][id][$eq]=${partner_id}${qs}`)
}

export async function addJob(job: Partial<AddOrUpdateJobModel>) {
    return axios.post<JobItemModel>(`${JOB}`, {
        data: job
    }).then(response => {
        return response.data
    })
}

export async function updateJob(job_id: number, job: Partial<AddOrUpdateJobModel>) {
    return axios.put<JobItemModel>(`${JOB}${job_id}`, {
        data: job
    }).then(response => {
        return response.data
    })
}

export async function updateJobStatus(job: UpdateJobStatusModel) {
    return axios.put<UpdateJobStatusModel>(`${JOB}${job.id}/?populate=*`, {
        data: job
    }).then(response => {
        return response.data
    })
}

export async function addJobSkill(add_skill: AddJobSkillToJob) {
    return axios.post<JobSkillsByJobIDModel>(`${JOB}${add_skill.job_id}/skills`, add_skill)
}

export async function updateJobSkill(update_skill: UpdateJobSkillModel) {
    return axios.put<JobSkillItemModel>(`${JOB_SKILL}${update_skill.job_skill_id}`, {
        data: update_skill
    }).then(response => {
        return response.data
    })
}

export async function deleteJobSkill(delete_skill: DeleteJobSkillModel) {
    return axios.delete<JobSkillItemModel>(`${JOB_SKILL}${delete_skill.job_skill_id}`)
}

export async function addJobTechStack(add_tech_stack: AddJobTechStackToJob) {
    return axios.post<JobTechStacksByJobIDModel>(`${JOB}${add_tech_stack.job_id}/tech_stacks`, add_tech_stack)
}

export async function updateJobTechStack(update_tech_stack: UpdateJobTechStackModel) {
    return axios.put<JobTechStackItemModel>(`${JOB_TECH_STACK}${update_tech_stack.job_tech_stack_id}`, {
        data: update_tech_stack
    }).then(response => {
        return response.data
    })
}

export async function deleteJobTechStack(delete_tech_stack: DeleteJobTechStackModel) {
    return axios.delete<JobTechStackItemModel>(`${JOB_TECH_STACK}${delete_tech_stack.job_tech_stack_id}`)
}

export function getJobSkillsByJobID(id: number) {
    return axios.get<JobSkillsByJobIDModel>(`${JOB}${id}/skills`)
}

export function getJobTechStacksByJobID(id: number) {
    return axios.get<JobTechStacksByJobIDModel>(`${JOB}${id}/tech_stacks`)
}

{/* Location */}

export async function getLocationList() {
    return axios.get<LocationListModel>(`${LOCATION}`)
}

{/* Cost to Company */}

export async function getCostToCompanyList() {
    return axios.get<CostToCompanyListModel>(`${COSTTOCOMPANY}`)
}

{/* Hourly Rate */}

export async function getHourlyRateList() {
    return axios.get<HourlyRateListModel>(`${HOURLYRATE}`)
}

{/* Hours Available */}

export async function getHoursAvailableList() {
    return axios.get<HoursAvailableListModel>(`${HOURS_AVAILABLE}`)
}

{/* Industry */}

export async function getIndustryList() {
    return axios.get<IndustryListModel>(`${INDUSTRY}`)
}

{/* Job Status */}

export async function getJobStatusList() {
    return axios.get<JobStatusListModel>(`${JOB_STATUS}`)
}

{/* Marketer Status */}

export async function getMarketerStatusList() {
    return axios.get<MarketerStatusListModel>(`${MARKETER_STATUS}`)
}

{/* Main Skill */}

export async function getMainSkillList() {
    return axios.get<MainSkillListModel>(`${MAIN_SKILL}`)
}

{/* Marketer Job Match */}

export async function getMarketerJobStatusList() {
    return axios.get<MarketerJobStatusListModel>(`${MARKETER_JOB_STATUS}`)
}


export async function getMarketerJobMatchByID(id: number) {
    return axios.get<MarketerJobMatchItemModel>(`${MARKETER_JOB_MATCH}${id}/?populate[0]=job&populate[1]=marketer&populate[2]=marketer_job_status&populate[3]=job.job_skills&populate[4]=job.job_tech_stacks&populate[5]=job.partner&populate[6]=job.location&populate[7]=job.main_skill&populate[8]=job.cost_to_company&populate[9]=job.hourly_rate&populate[10]=job.hours_available&populate[11]=marketer_job_matches&populate[12]=marketer.main_skill&populate[13]=marketer.marketer_skills&populate[14]=marketer.marketer_tech_stacks&populate[15]=marketer.marketer_status&populate[16]=marketer.location&populate[17]=marketer.cost_to_company&populate[18]=marketer.hourly_rate&populate[19]=marketer.hours_available&populate[20]=marketer.industries&populate[21]=marketer_declined_status`)
}

export async function getMarketerJobMatchListByMarketerID(marketer_id: number, page: number, query: string, status: string) {
    let qs
    let qs_status: string = ""
    if (page) {
        qs = `?pagination[page]=${page}&pagination[pageSize]=${PAGE_SIZE}`
    } else {
        qs = `?pagination[page]=1&pagination[pageSize]=${PAGE_SIZE}`
    }
    if (query) {
        qs = qs + `&search=${query}`
    }
    if (status) {
        let status_array = [0]
        switch(status) {
            case "pending":
                status_array = [JOB_MATCH_STATUS_PENDING]
                break;
            case "applications":
                status_array = [JOB_MATCH_STATUS_IN_REVIEW,JOB_MATCH_STATUS_TALENT_ADVISOR_MEETING,JOB_MATCH_STATUS_TALENT_ADVISOR_APPROVED,JOB_MATCH_STATUS_CV_SENT_TO_PARTNER,JOB_MATCH_STATUS_1ST_INTERVIEW,JOB_MATCH_STATUS_2ND_INTERVIEW,JOB_MATCH_STATUS_3RD_INTERVIEW,JOB_MATCH_STATUS_DECLINED, JOB_MATCH_STATUS_NOT_INTERESTED]
                break;
            case "jobs":
                status_array = [JOB_MATCH_STATUS_SUCCESSFUL]
                break;
            default:
                status_array = [JOB_MATCH_STATUS_PENDING, JOB_MATCH_STATUS_IN_REVIEW,JOB_MATCH_STATUS_TALENT_ADVISOR_MEETING,JOB_MATCH_STATUS_TALENT_ADVISOR_APPROVED,JOB_MATCH_STATUS_CV_SENT_TO_PARTNER,JOB_MATCH_STATUS_1ST_INTERVIEW,JOB_MATCH_STATUS_2ND_INTERVIEW,JOB_MATCH_STATUS_3RD_INTERVIEW, JOB_MATCH_STATUS_SUCCESSFUL, JOB_MATCH_STATUS_DECLINED, JOB_MATCH_STATUS_NOT_INTERESTED, JOB_MATCH_STATUS_NO_LONGER_VALID]
                break;
        }

        let count = 0
        _.forEach(status_array, function(value: string) {
            qs_status = qs_status + `&filters[marketer_job_status][id][$in][${count}]=${value}`
            count++;
        });
    }
    qs = qs + `&filters[marketer][id][0]=${marketer_id}&populate[0]=job&populate[1]=marketer&populate[2]=marketer_job_status&populate[3]=job.job_skills&populate[4]=job.location&populate[5]=job.main_skill&populate[6]=job.cost_to_company&populate[7]=job.hourly_rate${qs_status}`
    return axios.get<MarketerJobMatchListModel>(`${MARKETER_JOB_MATCH}${qs}`)
}

export async function getMarketerJobMatchListByJobID(job_id: number, page: number, query: string) {
    let qs = `?pagination[page]=1&pagination[pageSize]=${PAGE_SIZE}`
    if (page) {
        qs = `?pagination[page]=${page}&pagination[pageSize]=${PAGE_SIZE}`
    }
    if (query) {
        qs = qs + `&search=${query}`
    }
    qs = qs + `&populate[0]=job&populate[1]=marketer&populate[2]=marketer.main_skill&populate[3]=marketer_job_status&populate[4]=job.job_skills&populate[5]=job.location&populate[6]=job.main_skill&populate[7]=job.cost_to_company&populate[8]=job.hourly_rate&filters[job][id][$eq][0]=${job_id}`
    return axios.get<MarketerJobMatchListModel>(`${MARKETER_JOB_MATCH}${qs}`)
}

export async function updateMarketerJobMatchStatus(marketer_job_match_id: number, marketer_job_match_status_id : number, marketer_declined_status_id: number) {
    let data = {}
    if(marketer_job_match_status_id == JOB_MATCH_STATUS_NOT_INTERESTED) {
        data = {
            marketer_job_status: marketer_job_match_status_id,
            marketer_declined_status: marketer_declined_status_id
        }
    } else {
        data = {
            marketer_job_status: marketer_job_match_status_id
        }
    }

    return axios.put<MarketerJobMatchListModel>(`${MARKETER_JOB_MATCH}${marketer_job_match_id}`, {data})
}

export async function updateMarketerJobMatchNotes(marketer_job_match_id: number, notes : string) {
    let data = {
        notes: notes
    }
    return axios.put<MarketerJobMatchListModel>(`${MARKETER_JOB_MATCH}${marketer_job_match_id}`, {data})
}

{/* Upload */}
export async function upload(file: any) {
    const formData = new FormData();
    formData.append("files", file)
    return axios.post<JobSummaryListModel>(`${UPLOAD}`, formData, {
        headers: {"Content-Type": "multipart/form-data"}
    })
}

export async function getSkillsList() {
    return axios.get(`${SKILL}`)
}


{/* Validate Email */}
export async function validateMarketerReferralEmail( email : string) {
    let data = {
        email_address: email
    }
    return axios.post<ValidateMarketerReferralEmailModel>(`${MARKETER}email`, {data})
}

export async function createReferral(referral: AddMarketerReferralFormModel) {

    let response = await axios.post<CreateMarketerReferralModel>(`${REFERRALS}`, {
        data: referral
    })
    console.log('createReferral', response.data)
    return response.data
    
}

export async function getReferralsByMarketerID(id: number) {
    return axios.get<MarketerReferralListModel>(`${REFERRALS}?populate[0]=referred&populate[1]=job&populate[2]=referred.marketer_status&filters[referrer][id][$eq]=${id}`)
}
