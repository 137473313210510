export interface HoursAvailableListModel {
    data: HoursAvailableModel[]
    meta: any
}

export interface HoursAvailableItemModel {
    data: HoursAvailableModel
    meta: any
}

export interface HoursAvailableModel{
    id: number;
    attributes: HoursAvailableAttributesModel;
}

export interface HoursAvailableAttributesModel {
    hours: string;
}

export const initialHoursAvailableAttributesModelState: HoursAvailableAttributesModel = {
    hours: "",
}

export const initialHoursAvailableModelState: HoursAvailableModel = {
    id: 0,
    attributes: initialHoursAvailableAttributesModelState
}

export const initialHoursAvailableListModelState: HoursAvailableListModel = {
    data: [initialHoursAvailableModelState],
    meta: null
}