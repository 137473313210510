export interface IndustryListModel {
  data: IndustryModel[]
  meta: any
}

export interface IndustryItemModel {
  data: IndustryModel
  meta: any
}

export interface IndustryModel{
  id: number;
  attributes: IndustryAttributesModel;
}

export interface IndustryAttributesModel {
  name: string;
}

export const initialIndustryAttributesState: IndustryAttributesModel = {
  name: ""
}

export const initialIndustryState: IndustryModel = {
  id: 0,
  attributes: initialIndustryAttributesState
}

export const initialIndustryListState: IndustryListModel = {
  data: [initialIndustryState],
  meta: null
}
