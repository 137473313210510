/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {UserModel} from "../../../../app/modules/auth/models/UserModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";

export function AsideMenuMain() {

    const intl = useIntl()
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    return (
        
         
        <>

            {user.user_type == "admin" ?

                <>
                    <AsideMenuItemWithSub
                        to='/partners'
                        title='Partners'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/abstract/abs022.svg'
                    >
                        <AsideMenuItem to='/partners/list' title='List' hasBullet={true}/>
                        <AsideMenuItem to='/partners/add' title='Add' hasBullet={true}/>
                    </AsideMenuItemWithSub>
                    <AsideMenuItemWithSub
                        to='/jobs'
                        title='Jobs'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/finance/fin006.svg'
                    >
                        <AsideMenuItem to='/jobs/list' title='List' hasBullet={true}/>
                        <AsideMenuItem to='/jobs/add' title='Add' hasBullet={true}/>
                    </AsideMenuItemWithSub>
                    <AsideMenuItemWithSub
                        to='/marketers'
                        title='Marketers'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/communication/com006.svg'
                    >
                        <AsideMenuItem to='/marketers/list' title='List' hasBullet={true}/>
                        <AsideMenuItem to='/marketers/add' title='Add' hasBullet={true}/>
                    </AsideMenuItemWithSub>
                </>
                :
                <>
                {user.password_set?   
                    <>
                        <AsideMenuItemWithSub
                            to='/account'
                            title='Profile'
                            fontIcon='bi-archive'
                            icon='/media/icons/duotune/general/gen022.svg'
                        >
                            <AsideMenuItem to='/account/overview' title='Overview' hasBullet={true}/>
                            <AsideMenuItem to='/account/manage-skills' title='Skills' hasBullet={true}/>
                            <AsideMenuItem to='/account/manage-tech-stack' title='Tech Stacks' hasBullet={true}/>
                            <AsideMenuItem to='/account/opportunities/pending' title='Opportunities' hasBullet={true}/>
                            <AsideMenuItem to='/account/opportunities/applications' title='Applications' hasBullet={true}/>
                            <AsideMenuItem to='/account/opportunities/jobs' title='Jobs' hasBullet={true}/>
                            {/* <AsideMenuItem to='/account/courses' title='Courses' hasBullet={true}/>
                            <AsideMenuItem to='/account/referrals' title='Referrals' hasBullet={true}/>
                            <AsideMenuItem to='/account/offers' title='Offers' hasBullet={true}/> */}
                            <AsideMenuItem to='/account/settings' title='Settings' hasBullet={true}/>
                        </AsideMenuItemWithSub>
                        <AsideMenuItemWithSub
                            to='/referrals/overview'
                            title='Referrals'
                            fontIcon='bi-archive'
                            icon='/media/icons/duotune/general/gen022.svg'
                        >
                            <AsideMenuItem to='/referrals/jobs' title='All Jobs' hasBullet={true}/>
                            <AsideMenuItem to='/referrals/status' title='Referral Status' hasBullet={true}/>
                        </AsideMenuItemWithSub>

                        <div className='menu-item'>
                            <div className='menu-content'>
                                <div className='separator mx-1 my-4'></div>
                            </div>
                        </div>
                        <div className='menu-item'>
                            <AsideMenuItem to='/terms' title='Terms & Conditions'/>
                        </div>
                    </>
                : 
                    <>
                    <AsideMenuItem to='/register' title='Registration' hasBullet={true}/>
                    <div className='menu-item'>
                        <div className='menu-content'>
                            <div className='separator mx-1 my-4'></div>
                        </div>
                    </div>
                    <div className='menu-item'>
                        <AsideMenuItem to='/terms' title='Terms & Conditions'/>
                    </div>
                    </> 
                }
                </>
            }
        </>
     

     
    
    )
}
