export interface HourlyRateListModel {
    data: HourlyRateModel[]
    meta: any
}

export interface HourlyRateItemModel {
    data: HourlyRateModel
    meta: any
}

export interface HourlyRateModel{
    id: number;
    attributes: HourlyRateAttributesModel;
}

export interface HourlyRateAttributesModel {
    rate: string;
}

export const initialHourlyRateAttributesModelState: HourlyRateAttributesModel = {
    rate: "",
}

export const initialHourlyRateModelState: HourlyRateModel = {
    id: 0,
    attributes: initialHourlyRateAttributesModelState
}

export const initialHourlyRateListModelState: HourlyRateListModel = {
    data: [initialHourlyRateModelState],
    meta: null
}