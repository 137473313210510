import {
  PartnerItemModel
} from "./PartnerModel";
import {LocationItemModel} from "./LocationModel";
import {CostToCompanyItemModel} from "./CostToCompanyModel";
import {HourlyRateItemModel} from "./HourlyRateModel";

export interface MarketerSkillListModel {
  data: MarketerSkillModel[]
  meta: any
}

export interface MarketerSkillItemModel {
  data: MarketerSkillModel
  meta: any
}

export interface MarketerSkillModel{
  id: number;
  attributes: MarketerSkillAttributesModel;
}

export interface MarketerSkillAttributesModel {
  skill_id: number;
  marketer_skill_id: number
  skill_name: string;
  sub_skill_name: string;
  years_experience: number;
  icon: string;
}

export interface UpdateMarketerSkillModel {
  marketer_skill_id: number
  years_experience: number;
}

export interface DeleteMarketerSkillModel {
  marketer_skill_id: number
}

export const initialMarketerSkillAttributesModelState: MarketerSkillAttributesModel = {
  skill_id: 0,
  marketer_skill_id: 0,
  skill_name: "",
  sub_skill_name: "",
  years_experience: 0,
  icon: ""
}

export const initialMarketerSkillModelState: MarketerSkillModel = {
  id: 0,
  attributes: initialMarketerSkillAttributesModelState
}

export const initialUpdateMarketerSkillModelState: UpdateMarketerSkillModel = {
  marketer_skill_id: 0,
  years_experience: 0
}

export const initialDeleteMarketerSkillModelState: DeleteMarketerSkillModel = {
  marketer_skill_id: 0
}

export const initialMarketerSkillListModelState: MarketerSkillListModel = {
  data: [initialMarketerSkillModelState],
  meta: null
}
