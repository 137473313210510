import {FC} from 'react'

const Colours: FC = () => {
  return (
    <>
        <a href="#" className="btn btn-white">White</a>
        <a href="#" className="btn btn-primary">Primary</a>
        <a href="#" className="btn btn-light">Light</a>
        <a href="#" className="btn btn-secondary">Secondary</a>
        <a href="#" className="btn btn-success">Success</a>
        <a href="#" className="btn btn-info">Info</a>
        <a href="#" className="btn btn-warning">Warning</a>
        <a href="#" className="btn btn-danger">Danger</a>
        <a href="#" className="btn btn-dark">Dark</a>
    </>
  )
}

export {Colours}
