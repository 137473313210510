/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { MasterInit } from '../../_metronic/layout/MasterInit'
import { UserModel } from '../modules/auth/models/UserModel'
import { Colours } from "../modules/errors/components/Colours";
import SignUpStepper from "../modules/signup/SignUpStepper"

const Routes: FC = () => {
    const isAuthorized: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    return (
        <>
            <Switch>
                <Route path='/colours' component={Colours}/>
                <Route path='/signup/:marketer_id?' component={SignUpStepper}/>
                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage/>
                    </Route>
                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    isAuthorized.user_type == "admin" ?
                    <Redirect from='/auth' to='/marketers'/>
                    :
                    isAuthorized.password_set ?
                    <Redirect from='/auth' to='/accounts'/>
                    :
                    <Redirect from='/auth' to='/register'/>

                )}

                <Route path='/error' component={ErrorsPage}/>
                <Route path='/logout' component={Logout}/>

                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to='/auth/login'/>
                ) : (
                    <>
                        <MasterLayout>
                            <PrivateRoutes/>
                        </MasterLayout>
                    </>
                )}
            </Switch>
            <MasterInit/>
        </>
    )
}

export {Routes}
