export interface LocationListModel {
  data: LocationModel[]
  meta: any
}

export interface LocationItemModel {
  data: LocationModel
  meta: any
}

export interface LocationModel{
  id: number;
  attributes: LocationAttributesModel;
}

export interface LocationAttributesModel {
  name: string;
}

export const initialLocationAttributesState: LocationAttributesModel = {
  name: ""
}

export const initialLocationState: LocationModel = {
  id: 0,
  attributes: initialLocationAttributesState
}

export const initialLocationListState: LocationListModel = {
  data: [initialLocationState],
  meta: null
}
