import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import "yup-phone";
import { useFormik } from 'formik'
import { initialCreateMarketerFormModelState, CreateMarketerFormModel } from "../auth/models/MarketerModel";
import { validateMarketerReferralEmail } from '../auth/redux/AuthCRUD';
import { signupMarketer } from "../auth/redux/AuthCRUD";
import { FormControl, TextField, Box, Button, Snackbar, Alert, FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { useHistory, Link } from "react-router-dom";

const createMarketerSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    phone_number: Yup.string().phone("ZA", true, 'Please enter a customer phone number in the format 071 000 0000').required('Please add a customer phone number').typeError('Please add a customer phone number'),
    terms: Yup.bool().required('You must accept the terms and conditions'),
    email_address: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
    password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
    confirm_password: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export type Props = {
    steps: any
    step: number
    handleNext: () => void
    handleBack: () => void
}

const SignUpAccount: React.FC<Props> = ({steps, step, handleNext, handleBack}) => {

    const history = useHistory(); 
    const [form_loading, setFormLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const [email, setEmail] = useState("");
    const [terms_error, setTermsError] = useState('')
    const [error, setError] = useState('')

    const handleCloseSnack = () => {    
        setOpenSnack(false);
        setError("")
    };

    useEffect(() => {
        (async () => {
          formik.setFieldValue('email_address', email, true)
          if(email.length > 3)  {
    
            const {data} = await validateMarketerReferralEmail(email)
            
            if(data.email_valid && !formik.errors.email_address) {
              setError("")
              setUserExists(false);
              setOpenSnack(false);
              formik.setFieldError('email_address', '')
            }
            
            if(!data.email_valid && !formik.errors.email_address) {
              setError("You have already signed up, Please login")
              formik.setFieldError('email_address', 'Marketer already in the community')
              setOpenSnack(true);
              setUserExists(true);
            }
          }
    
        })();
    }, [email]);
   
    const initialValues = initialCreateMarketerFormModelState
    const formik = useFormik<CreateMarketerFormModel>({
        initialValues,
        validationSchema: createMarketerSchema,
        onSubmit: (values) => {
            if(values.terms) {
                setFormLoading(true)
                setTimeout(async () => { 
                    try {
                        const marketer = await signupMarketer(values)
                        if(marketer) {
                            history.push({ pathname: `/signup/${marketer.data.id}`})
                            setFormLoading(false)
                            handleNext()
                        } else {
                            console.log('marketer already exists');
                            setFormLoading(false)
                            setError("You have already signed up, Please login")
                            formik.setFieldError('email_address', 'Marketer already in the community')
                            setOpenSnack(true);
                            setUserExists(true);
                        }
                        
                    } catch (e) {
                        console.log(e)
                    }
              
                }, 1000)
            } else {
                setError('Please Accept Terms & Conditions')
                setOpenSnack(true);
            }
        },
    })
 
    return (
        <>
        {userExists ?
        
            <div className='form card-header-stepper'>
                <Box sx={{textAlign: 'center'}}>
                    <p>If you have already signed up please go to the login page!</p>
                    <Link to={`/auth`} className='btn btn-primary'>Signin</Link>
                </Box>
            </div>
        
        :    
        
        <form onSubmit={formik.handleSubmit} noValidate className='form card-header-stepper'>
            {/* First Name */}
            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>First Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            {...formik.getFieldProps('first_name')}
                            id="first_name"
                            variant="standard"
                            error={(formik.touched.first_name && formik.errors.first_name) ? true : false}
                        />
                    </FormControl>
                </div>
            </div>

            {/* Last Name */}
            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Last Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            {...formik.getFieldProps('last_name')}
                            id="last_name"
                            variant="standard"
                            error={(formik.touched.last_name && formik.errors.last_name) ? true : false}
                        />
                    </FormControl>
                </div>
            </div>

            {/* Email */}
            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Email</span>
                </label>

                <div className='col-lg-8 fv-row'>
                    <FormControl fullWidth variant="standard">
                    <TextField
                        autoComplete='off'
                        value={email}
                        onChange={e => (setEmail(e.target.value))}
                        id="email"
                        variant="standard"
                        error={(formik.touched.email_address && formik.errors.email_address) ? true : false}
                    />
                    </FormControl>
                </div>
            </div>

            {/* Phone Number */}
            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Phone Number</span>
                </label>

                <div className='col-lg-8 fv-row'>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            {...formik.getFieldProps('phone_number')}
                            id="phone_number"
                            variant="standard"
                            error={(formik.touched.phone_number && formik.errors.phone_number) ? true : false}
                        />
                    </FormControl>
                </div>
            </div>

            {/* Password */}
            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Password</span>
                </label>

                <div className='col-lg-8 fv-row'>
                    <FormControl fullWidth variant="standard">
                    <TextField
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        id="password"
                        variant="standard"
                        error={(formik.touched.password && formik.errors.password) ? true : false}
                    />
                    </FormControl>
                </div>
            </div>

            {/* Confirm Password */}
            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Confirm password</span>
                </label>

                <div className='col-lg-8 fv-row'>
                    <FormControl fullWidth variant="standard">
                    <TextField
                        autoComplete='off'
                        {...formik.getFieldProps('confirm_password')}
                        id="confirm_password"
                        variant="standard"
                        error={(formik.touched.confirm_password && formik.errors.confirm_password) ? true : false}
                    />
                    </FormControl>
                </div>
            </div>

            {/* Terms & Conditions */}
            <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Accept Terms & Conditions</span>
                </label>

                <div className='col-lg-4 fv-row'>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox {...formik.getFieldProps('terms')} />} label={`I Accept the terms & conditions`} />
                    </FormGroup>
                </div>

                <div className='col-lg-4 fv-row' style={{textAlign: 'right', paddingTop: '9px'}}>
                    <FormGroup>
                        <Link to={'/terms'}>View Terms & Conditions</Link>
                    </FormGroup>
                </div>
            </div>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={step === 0 ||  step === 1 }
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button className='btn btn-primary' type='submit' disabled={form_loading || error.length !== 0}>
                    {!form_loading && step === steps.length - 1 ? 'Finish' : 'Next'}
                    {form_loading && (
                        <span className='indicator-progress' style={{display: 'block'}}> Please wait...{' '} <span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>
                    )}
                </Button>
            </Box>
        </form>
        
        }
        <Snackbar open={openSnack} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleCloseSnack} severity={'error'} sx={{ width: '100%' }}>
                {error}
            </Alert>
        </Snackbar>
        </>
    )
    

}
export {SignUpAccount}