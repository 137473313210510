export interface MarketerSkillsByMarketerIDModel {
    data: {
        skill_list: string[]
        added_skills: MarketerSkillsByMarketerIDSkillModel[]
        available_skills: MarketerSkillsByMarketerIDAvailableSkillsModel[]
    },
    meta: any | null
}

export interface MarketerSkillsByMarketerIDSkillModel {
    skill_id: number;
    skill_name: string;
    sub_skill_name: string;
    years_experience: number;
    icon: string;
}

export interface MarketerSkillsByMarketerIDAvailableSkillsModel {
    skill_name: string;
    sub_skill_list: [MarketerSkillsByMarketerIDSkillModel];
}

export interface AddMarketerSkillToMarketer {
    marketer_id: number;
    skill_id: number[];
    skill_name: string;
    sub_skill_name: string;
    years_experience: number;
}

export const initialMarketerSkillsByMarketerIDSkillModelState : MarketerSkillsByMarketerIDSkillModel = {
    skill_id: 0,
    skill_name: "",
    sub_skill_name: "",
    years_experience: 0,
    icon: ""
}

export const initialMarketerSkillsByMarketerIDAvailableSkillsModelState : MarketerSkillsByMarketerIDAvailableSkillsModel = {
    skill_name: "",
    sub_skill_list: [initialMarketerSkillsByMarketerIDSkillModelState]
}

export const initialMarketerSkillsByMarketerIDModelState : MarketerSkillsByMarketerIDModel = {
    data: {
        skill_list: [""],
        added_skills: [initialMarketerSkillsByMarketerIDSkillModelState],
        available_skills: [{
            skill_name: "",
            sub_skill_list: [initialMarketerSkillsByMarketerIDSkillModelState]
        }]
    },
    meta: {}
}

export const initialAddMarketerSkillToMarketerState : AddMarketerSkillToMarketer = {
    marketer_id: 0,
    skill_id: [],
    skill_name: "",
    sub_skill_name: "",
    years_experience: 0
}
