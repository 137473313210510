/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../helpers'
import { Slider } from '@mui/material';
import * as Yup from 'yup'
import _ from "lodash";
import {useFormik} from 'formik'

import {UpdateMarketerTechStackModel, initialUpdateMarketerTechStackModelState} from "../../../app/modules/auth/models/MarketerTechStackModel";
import { updateMarketerTechStack } from '../../../app/modules/auth/redux/AuthCRUD'

export type Props = {
  show: boolean
  list_item: any
  handleClose: () => void
}

const updateTechStackSchema = Yup.object().shape({
  marketer_tech_stack_id: Yup.number().integer(),
  years_experience: Yup.number().integer().min(1, "Please select years experience").required('Years experience is required'),
})

const UpdateMarketerTechStackModal: React.FC<Props> = ({show, handleClose, list_item }) => {

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      formik.setFieldValue('marketer_tech_stack_id', list_item.marketer_tech_stack_id ? list_item.marketer_tech_stack_id : 0)
      formik.setFieldValue('years_experience', list_item.years_experience ? list_item.years_experience : 0)
      setLoading(false)
    })()
}, [list_item]);

  const initialValues = initialUpdateMarketerTechStackModelState
  const formik = useFormik<UpdateMarketerTechStackModel>({
    initialValues,
    validationSchema: updateTechStackSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
        setLoading(true)
        setTimeout(async () => {
            const update_marketer_tech_stack : UpdateMarketerTechStackModel = {
                marketer_tech_stack_id: values.marketer_tech_stack_id,
                years_experience: values.years_experience
            }
            await updateMarketerTechStack(update_marketer_tech_stack)
            .then(result => {
                setSubmitting(false);
                setLoading(false)
                resetForm({});
                handleClose();
              }, errors => {});
        }, 1000)
    },
  })
  

  function valuetext(value: any) {
    if(value == 10){
        return `${value}+ years`;
    } else if (value == 1){
        return `${value} year`;
    } else {
        return `${value} years`;
    }
  }
  
  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_update_years_experience'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={show}
      animation={false}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        <div  id='kt_update_years_experience_form'>
          {/*begin::Header*/}
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <h5 className='fw-bold m-0'>Update Years Experience</h5>
            <div className='d-flex ms-2'>
              {/*begin::Close*/}
              <div
                className='btn btn-icon btn-sm btn-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
              {/*end::Close*/}
            </div>
          </div>
          {/*end::Header*/}
              {/*begin::Body*/}
              <div className='d-block'>
                <div className='d-flex flex-column w-100 me-2 py-5 ps-8 pe-5'>
                    <span className='fw-bolder mb-1 fs-7'>
                        Please update the number of years of experience for <b>{list_item.sub_tech_stack_name}</b>
                    </span>
                </div>
                <form onSubmit={formik.handleSubmit} noValidate className='form d-flex flex-column w-100 me-2 py-5 ps-8 pe-5'>
                  <div className='card'>
                    <div className='row mb-6'>
                        <div className='col-lg-12 fv-row'>
                            <Slider
                                aria-label="Default"
                                defaultValue={list_item.years_experience}
                                valueLabelDisplay="auto"
                                step={1}
                                getAriaValueText={valuetext}
                                valueLabelFormat={valuetext}
                                marks={false}
                                min={0}
                                max={10}
                                {...formik.getFieldProps('years_experience')}
                            />
                            {formik.touched.years_experience && formik.errors.years_experience && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.years_experience}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-center py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Save Changes'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                      Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                  </div>
                </form>
              </div>
              {/*end::Body*/}
        </div>
        {/*end::Form*/}
      </div>
    </Modal>
  )
}

export {UpdateMarketerTechStackModal}
