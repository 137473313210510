import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {ThemeProvider} from '@mui/material/styles';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import theme from '../theme';

type Props = {
    basename: string
}

const App: React.FC<Props> = ({basename}) => {

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyDQd0vTnLXMJERnDOZnwZ7XVTpwsi0xExc",
        authDomain: "fundi-digital.firebaseapp.com",
        projectId: "fundi-digital",
        storageBucket: "fundi-digital.appspot.com",
        messagingSenderId: "169511249672",
        appId: "1:169511249672:web:d000ba44428684e8fc6ebd",
        measurementId: "G-ERZ1WJJHVP"
    };

// Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={<LayoutSplashScreen/>}>
                <BrowserRouter basename={basename}>
                    <I18nProvider>
                        <LayoutProvider>
                            <AuthInit>
                                <Routes/>
                            </AuthInit>
                        </LayoutProvider>
                    </I18nProvider>
                </BrowserRouter>
            </Suspense>
        </ThemeProvider>
    )
}

export {App}
